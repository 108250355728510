import { Dropdown } from "office-ui-fabric-react";
import * as React from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

export class PhoneInputCountrySelect extends React.Component {
  getCountries() {
    return getCountries().map((c) => ({
      key: c,
      text: `${en[c]} +${getCountryCallingCode(c)}`
    }))
      .sort((previous, current) => previous.text.localeCompare(current.text));
  }

  render() {
    // @ts-expect-error
    const { value, onChange } = this.props;

    return (
      <Dropdown
        id="country_selection_dropdown"
        options={this.getCountries()}
        selectedKey={value}
        onChange={(_, selectedOption) => onChange(selectedOption?.key)}
      />
    );
  }
}
