import { Checkbox, Stack } from "office-ui-fabric-react";
import React from "react";
import { SelectedReportElements } from "../../../reports/common";
import { DefaultResetButton } from "./DefaultResetButton";
import "./ReportElementsTab.style.scss";

export interface IReportElement {
  name: string
  label: string
  selected: boolean
}

interface IReportElementsProps {
  reportElements: IReportElement[]
  selectedReportElements: Set<String>
  onChange: (ev?: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void
  showDefaultReset?: boolean
  onDefaultReset?: () => void
}

export const ReportElementsTab = ({
  reportElements,
  selectedReportElements,
  onChange,
  showDefaultReset = false,
  onDefaultReset = () => {}
}: IReportElementsProps) => {
  const stackTokens = { childrenGap: 10 }; // Used to add spacing between example checkboxes

  return (
    <>
      <div className="report-elements-tab-header">
        <SelectedReportElements selected={selectedReportElements.size} total={reportElements.length} />
        {showDefaultReset && <DefaultResetButton onClick={onDefaultReset} />}
      </div>
      <Stack className="report-elements-tab-body" tokens={stackTokens}>
        {reportElements.map((reportElement) => {
          return (
            <Checkbox
              key={reportElement.name}
              name={reportElement.name}
              label={reportElement.label}
              checked={selectedReportElements.has(reportElement.name)}
              onChange={onChange}
            />
          );
        })}
      </Stack>
    </>
  );
};
