import React from "react";
import TextareaAutosize from "react-autosize-textarea";

export class TextareaAutoSizeWrapper extends React.Component<TextareaAutosize.Props> {
  private textarea: HTMLTextAreaElement;
  private _lastValue: string;
  private observer: MutationObserver;

  componentDidMount() {
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const me = this;
    const target = this.textarea;
    this._lastValue = target.value;
    const callback = function(mutationsList: any, observer: any) {
      if (me._lastValue != target.value) {
        const event = new Event("change", { bubbles: true });
        target.dispatchEvent(event);
        me._lastValue = target.value;
      }
    };
    this.observer = new MutationObserver(callback);
    this.observer.observe(target, config);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    return <TextareaAutosize {...this.props} ref={(x: HTMLTextAreaElement) => (this.textarea = x)} />;
  }
}
