import React, { useEffect } from "react";
import { ErrorScreen } from "../components/ErrorScreen";
import { LoadingScreen } from "../components/LoadingScreen";
import { PacmanLoaderWrapped } from "../components/PacmanLoaderWrapped";
import { useCorsisQuery, useIsMount } from "../hooks";
import { unblockPage } from "./renderers";

export const QueryWrapper = ({ query, variables, output, extraVars }) => {
  const [stateData, setStateData] = React.useState(null);
  const isMount = useIsMount();

  if (!output) {
    output = variables;
    variables = {};
  }
  if (!variables) {
    variables = {};
  }
  let loader = <LoadingScreen type={"shimmer"} />;
  if (extraVars.pacmanLoader) {
    loader = <PacmanLoaderWrapped />;
  }
  const { loading, error, data, refetch } = useCorsisQuery(query, variables, extraVars);

  useEffect(() => {
    if (!extraVars.forceRefetch && isMount) {
      return;
    }
    void refetch(variables).then(() => {
      setStateData(null);
    });
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    setStateData(null);
  }, [data]);

  if (loading) {
    return loader;
  } else if (error != null) {
    return <ErrorScreen error={error} />;
  }

  const forceUpdate = () => {
    window.apolloClient.query({ query, variables, fetchPolicy: "cache-only" }).then((res) => {
      if (isMount) {
        setStateData(res.data);
      }
    });
  };
  unblockPage();

  return output(stateData || data, { refetch, forceUpdate });
};
