import React from "react";
import Wizard2FA from "../Wizard2FA";
import { Prompt } from "./Prompt";

export class Prompt2FA extends Prompt {
  state = {
    show: false
  };

  prompt_name = "p_2fa";

  primaryClick = () => {
    this.setState({ show: true });
  };

  render() {
    if (this.state.show) {
      return <Wizard2FA onClose={() => this.setState({ show: false })} />;
    }
    return super.render();
  }
}
