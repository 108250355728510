import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
import { GQLTag } from "../../../schemas/schema";
import { Modal } from "../Modal";

interface IPopupTagSelectorProps {
  value: GQLTag[]
  allTags: GQLTag[]
  onChange: (tags: GQLTag[]) => void
}

export class PopupTagSelector extends React.Component<IPopupTagSelectorProps> {
  state = {
    expanded: false,
    tags: {}
  };

  expand = () => this.setState({ expanded: true });
  close = () => this.setState({ expanded: false });
  categories = ["Modules", "Context", "Compliance"];
  componentWillMount() {
    this.categories.forEach((cat) => {
      this.state.tags[cat] = this.props.value.filter((x) => x.category!.name!.toLowerCase() == cat.toLowerCase());
    });
  }

  setTags(category_name, newTags) {
    const { tags } = this.state;
    tags[category_name] = newTags || [];
    this.setState({ tags });
    this.props.onChange(this.selectedTags);
  }

  get selectedTags() {
    // @ts-expect-error: Object.values
    return [].concat(...Object.values(this.state.tags)) as GQLTag[];
  }

  renderPopup() {
    return (
      <Modal title={"Manage Tags"} onClose={this.close}>
        <table>
          <thead>
            <th>Category</th>
            <th>Tag Name</th>
          </thead>
          <tbody>
            {this.categories.map((cat) => (
              <tr key={cat}>
                <td>{cat}</td>
                {this.renderTagSelect(cat)}
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    );
  }

  private renderTagSelect(cat: string) {
    const allTags = this.props.allTags.filter((x) => x.category!.name!.toLowerCase() == cat.toLowerCase());
    return (
      <Select
        value={this.state.tags[cat]}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999999 }) }}
        options={allTags}
        getOptionLabel={(option: GQLTag) => `${option.name}`}
        getOptionValue={(option: GQLTag) => option.id + ""}
        className="basic-multi-select"
        onChange={(tags) => this.setTags(cat, tags)}
        isSearchable={true}
        isMulti
      />
    );
  }

  render() {
    return (
      <>
        {this.state.expanded && this.renderPopup()}
        <div className="popup-tag-selector" onClick={this.expand}>
          <div className="tag-names">{this.selectedTags.map((x) => x.name).join(", ") || "Select Tags..."}</div>
          <FontAwesomeIcon icon={"pencil"} />
        </div>
      </>
    );
  }
}
