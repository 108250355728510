import { Field } from "formik";
import { FormikCheckbox, FormikDatePicker, FormikTextField } from "formik-office-ui-fabric-react/dist";
import gql from "graphql-tag";
import React from "react";
import { firstBy } from "thenby";
import { SearchSelect } from "../../common";
import { ReportEditForm } from "../../ReportEditForm";
import ProgressModal from "../../common/ProgressModal";
import { deep_clone } from "@/common/utils";
import { GQLClient, GQLProject } from "@/schemas/schema";
import { navigate } from "@/admin/utils";
import { WebSocketDeferModal } from "@/common/components/AsyncProcessModal";

export class ReportCloneForm extends ReportEditForm {
  protected get title() {
    return "Clone Report";
  }

  protected extraFields = new Set(["client", "project"]);

  required_fields = ["name", "client_id", "project_id"];
  show_tool_fields = false;

  formQuery = gql`
    query {
      clients {
        id
        name
        company_ids
      }
      projects {
        id
        name
        client {
          id
        }
      }
    }
  `;

  editMutation = gql`
    mutation clone_report($input: ReportAttributes!) {
      clone_report(attributes: $input) {
        channel
      }
    }
  `;

  initialValuesFromObject(initialValues: any, gqlObject: GQLObject) {
    initialValues = super.initialValuesFromObject(initialValues, gqlObject);
    initialValues.client_id = initialValues.project_id = null;
    initialValues.test = true;

    return initialValues;
  }

  protected renderFormFields(props, res) {
    const clients = deep_clone(res.clients).sort(firstBy((x: any) => x.name));
    // @ts-expect-error
    const { modal } = this.state;

    return (
      <>
        <Field label="Name" name="name" required component={FormikTextField} />
        <Field
          name="client_id"
          required
          label="Client"
          options={clients.map(this.objectToOption)}
          component={SearchSelect}
        />
        <Field
          name="project_id"
          required
          label="Project"
          component={SearchSelect}
          showAllOptions
          options={res.projects
            .filter(this.selectedClientOnly(res.clients.find((x) => x.id == props.values.client_id)))
            .map(this.objectToOption)}
        />
        <Field label="Job code" name="job_code" required component={FormikTextField} />
        <Field label="Published at" name="published_at" required component={FormikDatePicker} />
        <Field label="Test" name="test" required component={FormikCheckbox} />
        { modal ?? null }
      </>
    );
  }

  private selectedClientOnly(client: GQLClient | null) {
    return function(project: GQLProject) {
      if (!client) {
        return false;
      }
      return project.client!.id == client.id;
    };
  }

  private redirectToClonedReport(reportId: number) {
    navigate(`reports/${reportId}/edit`)();
  }

  protected onSubmitCompleted({ data }) {
    const modal = <WebSocketDeferModal
        channel={data.clone_report.channel}
        onClose={() => this.setState({ modal: null })}
        />;
    this.setState({ modal });
  }
}
