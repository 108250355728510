import React from "react";

export class Reload extends React.Component {
  componentDidMount() {
    window.location.reload();
  }

  render() {
    return null;
  }
}
