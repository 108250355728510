import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IProps {
  initialValue: string
  onSave: (s: string) => Promise<void>
}

export class AutoSaveTextField extends React.Component<IProps> {
  private timeout: number;
  state = {
    text: "",
    savePending: null
  };

  componentWillMount(): void {
    this.state.text = this.props.initialValue;
  }

  handleKeyPress(e: React.ChangeEvent<HTMLInputElement>): void {
    const text = e.target.value;
    this.setState({ savePending: true, text });
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.props.onSave(this.state.text).then(() => this.setState({ savePending: false }));
    }, 3000);
  }

  render() {
    return (
      <React.Fragment>
        <input type={"text"} onChange={this.handleKeyPress.bind(this)} value={this.state.text} />
        {this.state.savePending == false && <FontAwesomeIcon icon={"check"} />}
        {this.state.savePending && <FontAwesomeIcon icon={"clock"} />}
      </React.Fragment>
    );
  }
}
