import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import "./ReportBlock.scss";
interface IProps {
  id?: string
  header?: JSX.Element
  title?: string
  noCollapse?: boolean
  startCollapsed?: boolean
  className?: string
  headerClassName?: string
  bottomBar?: JSX.Element
  onToggle?: (boolean) => void
  children?: JSX.Element | JSX.Element[]
}

export class ReportBlock extends React.Component<IProps> {
  state = {
    collapsed: false
  };

  constructor(props) {
    super(props);

    if (!props.noCollapse && props.startCollapsed) {
      this.state.collapsed = true;
    }
  }

  togglePanel = () => {
    if (this.props.noCollapse) {
      return;
    }

    const newCollapsed = !this.state.collapsed;
    this.setState({ collapsed: newCollapsed }, () => {
      this.props.onToggle && this.props.onToggle(newCollapsed);
    });
  };

  render() {
    let { header, title, headerClassName, className, bottomBar, children, noCollapse } = this.props;
    const { collapsed } = this.state;

    if (!header) {
      header = <h2>{title}</h2>;
    }

    return (
      <div
        id={this.props.id}
        className={cn("report-indicator-entry", className)}
        data-testid={this.props["data-testid"]}
      >
        <div className="report-indicator-info" onClick={this.togglePanel} role="button">
          <div className={cn("report-indicator-info-header", headerClassName)}>
            <div className="report-indicator-header">{header}</div>
            {!noCollapse && (
              <FontAwesomeIcon className="collapsed" icon={["fas", !collapsed ? "caret-up" : "caret-down"]} />
            )}
          </div>
        </div>
          <div className={cn({ collapsed })}>
            <div className="report-indicator-body">{children}</div>
            {this.props.bottomBar && <div className="bottom-bar">{bottomBar}</div>}
          </div>
      </div>
    );
  }
}
