import Fuse from "fuse.js";
import { GQLReport, GQLReportFinding } from "@/schemas/schema";
import { FindingTypes, HighRecommendationPriorities } from "../constants";

export function deep_clone(object: any) {
  try {
    return JSON.parse(JSON.stringify(object));
  } catch (e) {
    console.warn("Problem deep cloning");
    console.warn(e);
    return {};
  }
}

export function copyKey(origin, target) {
  return function(item) {
    item[target] = item[origin];
    return item;
  };
}

export const sortOrderBasedOnKeysArray = (keysArray: string[]) => {
  const elementOrders = keysArray.reduce((orders, key, index) => ({ ...orders, [key]: index }), {});
  return (elementKey) => elementOrders[elementKey] || 0;
};

export function onlyUnique(value: any, index: number, self: any) {
  return self.indexOf(value) === index;
}

export function onlyUniqueByKey(key: string) {
  return function(value: any, index: number, self: any) {
    return self.map((n: any) => n[key]).indexOf(value[key]) === index;
  };
}

export function fuzzySearcher(haystack: any[], keys = ["name"], extraOptions = {}) {
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys,
    ...extraOptions
  };
  return new Fuse(haystack, options); // "list" is the item array
}

export function excludeNumbers(obj) {
  return !obj.match(/[0-9]+/);
}

export const isQualifiedForKeyFinding = (reportFinding: GQLReportFinding): boolean =>
  reportFinding.finding_type === FindingTypes.strength ||
  HighRecommendationPriorities.includes(reportFinding.report_recommendation?.priority ?? "");

export const redirectJiraSessions = (report: GQLReport) => {
  if (!report.publish || !report.pending_suggestions?.length) {
    return;
  }

  const message = `This report has ${report.pending_suggestions.length} suggestions to be submitted to JIRA. Would you like to do that now?`;
  if (confirm(message)) {
    const suggestionIds = report.pending_suggestions.map((x) => x.id).join("-");
    window.location.href = `/admin/jira_sessions/apply-suggestions/${suggestionIds}?source=${window.location.href}`;
  }
};

export function ensureNumber(n: any) {
  if (typeof n === "number") {
    return n;
  }
  if (!n) {
    return 0;
  }
  return parseInt(n);
}
