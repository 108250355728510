import { Dropdown, Label, TextField } from "office-ui-fabric-react";
import React from "react";
import { withTooltip } from "../../lib";

export interface MonthYearFieldProps {
  tooltipIcon: any
  tooltip: string
  label: string
  selectedMonth?: string
  selectedYear?: string
  years: string[]
  onChangeMonth: (e, v) => void
  onChangeYear: (e, v) => void
  dataTestId: string
}

export class MonthYearField extends React.Component<MonthYearFieldProps> {
  months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((item) => ({ key: item, text: item }));
  years = this.props.years.map((item) => ({ key: item, text: item }));

  render() {
    return <>
              <div>
                <Label>
                  {this.props.label}{withTooltip(this.props.tooltip, this.props.tooltipIcon)}
                </Label>
              </div>
              <div className="flex">
                <div className="flex-container">
                  <div>
                    <label>Month:</label>
                  </div>
                  <div className="flex-item-grow-1">
                  <Dropdown
                    selectedKey={this.props.selectedMonth}
                    placeholder="Select"
                    options={this.months}
                    onChange={this.props.onChangeMonth}
                    data-testid={`${this.props.dataTestId}-month`}
                  />
                  </div>
                </div>
                <div className="flex-container ml-16">
                  <div>
                    <label>Year:</label>
                  </div>
                  <div className="flex-item-grow-1">
                  <Dropdown
                    onChange={this.props.onChangeYear}
                    selectedKey={this.props.selectedYear}
                    placeholder="Select"
                    options={this.years}
                    data-testid={`${this.props.dataTestId}-year`}
                  />
                  </div>
                </div>
              </div>
            </>;
  }
}
