import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApolloError } from "apollo-client";
import React from "react";

export class ErrorScreen extends React.Component<{ error: ApolloError }> {
  render() {
    let { error } = this.props;
    let showGenericTitle = true;

    console.log("tt- error", error);

    if (error.graphQLErrors.length > 0) {
      // @ts-expect-error
      const isReportNotFound = error.graphQLErrors[0].path == "report" && error.graphQLErrors[0].message == "not_found";
      if (isReportNotFound) {
        showGenericTitle = false;
        error = JSON.parse(JSON.stringify(error));
        error.graphQLErrors[0].message =
          "The report you requested has not been found or you do not have permission to view.\nPlease contact" +
          " your project manager or a system administrator for help or additional information.";
      }
    }

    return (
      <div style={{ width: "100%", height: "100%", position: "fixed" }}>
        <div className={"error-box"} style={{ top: "36%", position: "relative", left: "0%" }}>
          <FontAwesomeIcon icon={["fal", "exclamation-square"]} />
          <div>
            {showGenericTitle && <h1>There was an error while rendering this box</h1>}
            {error.graphQLErrors
              .map((x) => x.message)
              .map((message) => (
                <h2 style={{ whiteSpace: "pre-wrap" }}>{message}</h2>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
