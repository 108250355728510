import * as React from "react";
import ReactDOM from "react-dom";
// @ts-expect-error
import { shallowEqual } from "shouldcomponentupdate-children";

export default class BaseComponent<P = {}, S = {}> extends React.Component<P, S> {
  unsub_tokens = [] as any[];
  update = () => this.forceUpdate();
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const willUpdate = shallowEqual(this.props, nextProps, this.state, nextState);
    return willUpdate;
  }

  componentWillUnmount(): void {
    this.unsub_tokens.map(PubSub.unsubscribe);
  }

  protected _getDOMElement(): Element {
    return ReactDOM.findDOMNode(this)! as Element;
  }

  protected updateStateHash(hash_name: string, values: any) {
    const h = this.state[hash_name] || {};
    for (const k in values) {
      h[k] = values[k];
    }
    const newState = {};
    newState[hash_name] = h;
    this.setState(newState);
    this.forceUpdate();
  }
}
