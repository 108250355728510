import { DefaultButton } from "office-ui-fabric-react";
import React from "react";

interface IDefaultResetProps {
  onClick: () => void
}

export const DefaultResetButton = ({ onClick }: IDefaultResetProps) => {
  return (
    <DefaultButton className="default-reset-btn" onClick={onClick}>
      Default Reset
    </DefaultButton>
  );
};
