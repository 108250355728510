function makeParseFunction(parser, parseMarkdown) {
  return function parse(input) {
    if (!input) { return []; }
    input = input.replace(/\[br\]/, "\n\n", "g"); // keeping old code behavior
    let parsedSource = parser.parse(input);
    if (!Array.isArray(parsedSource)) {
      parsedSource = [parsedSource];
    }

    parsedSource.map((element) => {
      if (element && element.type === "markdown") {
        element.value = parseMarkdown(element.value);
      }
    });
    return parsedSource;
  };
}
module.exports = {
  makeParseFunction
};
