import { DirectionalHint, IButtonStyles } from "office-ui-fabric-react";

export const actionButtonStyle = {
  menuIcon: { display: "none" },
  root: {
    padding: 0
  },
  label: {
    maxWidth: 385,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 600
  },
  flexContainer: {
    marginRight: "0 !important"
  }
} as IButtonStyles;

export const menuProps = {
  isBeakVisible: true,
  beakWidth: 20,
  directionalHint: DirectionalHint.bottomCenter
};
