import { gql } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { TextField } from "office-ui-fabric-react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { friendlyDateTime } from "../../../../admin/utils";
import { gqlMutation } from "../../../../common/lib";
import { HistoryComponentBase, HistoryComponentProps } from "./HistoryComponentBase";

interface HistoryMarkerProps extends HistoryComponentProps {
  marker_name: string
  marker_id: ID
}

export class HistoryMarker extends HistoryComponentBase<HistoryMarkerProps> {
  state = {
    edit: false,
    marker_name: "",
    timestamp: ""
  };

  mutation = gql`
    mutation history_marker_update($marker_id: ID!, $marker_name: String!, $timestamp: String) {
      update_history_marker(marker_id: $marker_id, marker_name: $marker_name, timestamp: $timestamp) {
        success
      }
    }
  `;

  constructor(props) {
    super(props);
    this.state.marker_name = props.marker_name;
    this.state.timestamp = props.timestamp;
  }

  submit(mutateFunc) {
    const variables = {
      marker_id: this.props.marker_id + "",
      marker_name: this.state.marker_name,
      timestamp: this.state.timestamp
    };
    mutateFunc({ variables });
    this.setState({ edit: false });
  }

  reset = () => {
    const { marker_name, timestamp } = this.props;
    this.setState({ marker_name, timestamp });
  };

  discard() {
    this.reset();
    this.setState({ edit: false });
  }

  render() {
    const { user, title } = this.props;
    const { edit, marker_name, timestamp } = this.state;
    if (!this.state.edit && !marker_name) {
      return null;
    }

    return gqlMutation(this.mutation, (mutateFunc) => (
      <div className="history-marker">
        <div className="desc">
          <FontAwesomeIcon icon={"flag"} className="flag" />
          {!edit && (
            <>
              <strong>{user.full_name}</strong> marked the report <strong>{marker_name}</strong>
            </>
          )}
          {edit && (
            <form onSubmit={() => this.submit(mutateFunc)}>
              <TextField autoFocus onChange={(e, marker_name) => this.setState({ marker_name })} value={marker_name} />
            </form>
          )}
        </div>
        <div className="timestamp">
          {!edit && friendlyDateTime(timestamp)}
          {edit && (
            <ReactDatePicker
              popperPlacement="left"
              // @ts-expect-error
              selected={moment(this.state.timestamp).toDate()}
              onChange={(timestamp) => this.setState({ timestamp })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          )}
        </div>
        {edit && (
          <span className="edit-icons">
            <a className="save" onClick={() => this.submit(mutateFunc)}>
              <FontAwesomeIcon icon={["fal", "check"]} />
            </a>
            <a className="discard" onClick={() => this.discard()}>
              <FontAwesomeIcon icon={["fal", "times"]} />
            </a>
          </span>
        )}
        {!edit && (
          <span className="version-history-edit" onClick={() => this.setState({ edit: true })}>
            <FontAwesomeIcon icon={"pencil"} className="pencil" />
          </span>
        )}
        <div className="clear" />
      </div>
    ));
  }
}
