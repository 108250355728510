import React from "react";
import { Modal } from "../../../../common/components";
import { gqlQuery } from "../../../../common/lib";
import { FieldHistory } from "./FieldHistory";
import { HistoryMarker } from "./HistoryMarker";
import { EventHistory } from "./EventHistory";

export class VersionHistoryBase<P extends { onClose: () => void }> extends React.Component<P> {
  get title() {
    return "";
  }

  get variables() {
    return {};
  }

  query: any = {};
  object_name = "";

  render() {
    return (
      <Modal open title="Version history" className="version-history" onClose={this.props.onClose}>
        <h3>{this.title}</h3>
        {gqlQuery(this.query, this.variables, (res) => this.renderHistory(res[this.object_name]), {
          fetchPolicy: "no-cache"
        })}
      </Modal>
    );
  }

  private renderHistory({ id, history_entries }: any) {
    return history_entries.map((entry, i) => {
      const Component = {
        FieldHistory,
        EventHistory,
        HistoryMarker
      }[entry.component];

      return (
        <span key={i} className="history-entry">
          <Component user={entry.user} title={entry.title} timestamp={entry.timestamp} mode={entry.mode} {...JSON.parse(entry.data)} />
        </span>
      );
    });
  }
}
