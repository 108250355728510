import { GQLReport, GQLUser } from "./schema";

export interface Report extends GQLReport {}

export function report_show_export_role(report: GQLReport, value: string) {
  return !report.hide_export_roles!.includes(value);
}

export function report_users_for_role(report: Report, users: GQLUser[], role: string) {
  const userIds = report.roles!.filter((x) => x.role_name == role).map((x) => x.user_id);
  return users.filter((x) => userIds.includes(x.id));
}

export function report_track_smes(report: Report, users: GQLUser[]) {
  const userIds = [] as any[];
  for (const role of report.roles!) {
    if (role.role_name?.startsWith("Track-")) {
      userIds.push(role.user?.id);
    }
  }
  return users.filter((x) => userIds.includes(x.id));
}
