import cn from "classnames";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";

export const SortableContainerEl = SortableContainer(({ className = "", headers, children }) => {
  return (
    <ul className={cn(className, "controls track-selector")}>
      <li className="row">{headers}</li>
      {children}
    </ul>
  );
});
