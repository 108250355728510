import { Field } from "formik";
import { MessageBar, MessageBarType, TooltipHost } from "office-ui-fabric-react";
import React from "react";

export function showIf(condition) {
  return { style: { display: condition ? "" : "none" } };
}

export function strong(word: string | number, spacing = false) {
  const out = <strong>{word}</strong>;
  if (spacing) {
    return <>&nbsp;{out}&nbsp;</>;
  }
  return out;
}

export function blockPage(blockType = "hard", val = true) {
  PubSub.publishSync("page.loading-block", [val, blockType]);
}

export function unblockPage(): void {
  blockPage("hard", false);
}

export function withContext(context: React.Context<any>, output) {
  const Context = context;
  return <Context.Consumer>{(context) => output(context)}</Context.Consumer>;
}

interface TooltipOptions {
  key?: string
  className?: string
}

export function withTooltip(tooltip: string, content, opts?: TooltipOptions) {
  let tooltipHtml = <>{tooltip}</>;
  if (tooltip.includes("\n")) {
    tooltipHtml = (
      <>
        {tooltip.split("\n").map((x, i) => (
          <div key={i}>{x}</div>
        ))}
      </>
    );
  }

  return withHtmlTooltip(tooltipHtml, content, opts);
}

export function withHtmlTooltip(tooltip, content, opts?: TooltipOptions) {
  const key = opts?.key ?? `tooltip-host-${Math.random().toString(36)}-${Date.now()}`;
  return (
    <TooltipHost key={key} content={tooltip} hostClassName={opts?.className}>
      {content}
    </TooltipHost>
  );
}

export function showFieldError(form, field: typeof Field) {
  if (form.errors[field.name] && form.touched[field.name]) {
    const error = form.errors[field.name];
    if (error != `${field.name} is invalid`) {
      return (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
          {form.errors[field.name]}
        </MessageBar>
      );
    }
  }
  return null;
}
