import React from "react";
import ReactDOM from "react-dom";
import { getApolloClient } from "./lib/apollo";
import { ApolloProvider } from "@apollo/client";
import { Request2FA } from "./common/components";

// @ts-expect-error
window.global = window;
// // const timeoutHandler = new TimeoutHandler();
//
// interface PageContextBase {
//     lockPath: (path: string) => void
//     unlockPath: (path: string) => void
//     locked_paths: LockedPaths;
//
// }
//
// declare global {
//     interface Window {
//         page_context: PageContextBase;
//     }
// }

window.require_2fa = (func) => {
  const el = document.createElement("span");
  document.body.insertBefore(el, document.body.firstChild);
  const closeRequest = () => {
    ReactDOM.unmountComponentAtNode(el);
    el.parentNode!.removeChild(el);
  };
  ReactDOM.render(
        <ApolloProvider client={window.apolloClient}>
            <Request2FA callback={func} onClose={closeRequest}/>
        </ApolloProvider>,
        el
  );
};

export function loadReactContext(components) {
  document.addEventListener("DOMContentLoaded", () => {
    window.apolloClient = getApolloClient();
    document.querySelectorAll("[react-component]").forEach(el => {
      // @ts-expect-error
      const Component = components[el.getAttribute("react-component")];
      const props = JSON.parse(el.getAttribute("react-props") || "{}");
      const content = el.innerHTML;
      ReactDOM.render(
                <ApolloProvider client={window.apolloClient}>
                    <Component {...props}>{content}</Component>
                </ApolloProvider>,
                el
      );
    });
  });
}
