import { ApolloLink, Operation } from "@apollo/client";
import SubscriptionWorker from "./SubscriptionWorker";

interface WorkerDirectory {
  [index: string]: SubscriptionWorker
}

export class SubscriptionLink extends ApolloLink {
  static workers: WorkerDirectory = {};

  request(operation, forward) {
    const context = operation.getContext();
    if (context.isSubscription) {
      // add header
      operation.setContext({
        headers: {
          "X-Subscription": "true"
        }
      });
    }
    return forward(operation).map((data) => {
      const headers = operation.getContext().response.headers;
      if (headers?.get("X-Stream")) {
        this.startSubscription(operation, headers.get("X-Stream"));
      }
      return data;
    });
  }

  private startSubscription(operation: Operation, streamName: string) {
    // One fine day this will operate on a separate thread
    // let worker = new Worker(new URL("./SubscriptionWorker", import.meta.url), { type: "module" });
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete SubscriptionLink.workers[streamName];
    SubscriptionLink.workers[streamName] = new SubscriptionWorker(streamName);
  }
}
