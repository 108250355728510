import React from "react";
import { HistoryComponentBase, HistoryComponentProps } from "./HistoryComponentBase";

interface FieldHistoryProps extends HistoryComponentProps {
  comparison_html: any
  mode: "simple" | "visualEditor"
}

export class FieldHistory extends HistoryComponentBase<FieldHistoryProps> {
  renderInner() {
    const { comparison_html, mode } = this.props;

    if (mode === "simple") {
      return (
        <ul className="diffy-split-view">
          <li className="previous" dangerouslySetInnerHTML={{ __html: comparison_html.left }}/>
          <li className="current" dangerouslySetInnerHTML={{ __html: comparison_html.right }}/>
        </ul>
      );
    } else {
      return (
        <ul className="diffy-split-html-view fr-view" style={{ listStyle: "none" }}>
          <li className="previous" dangerouslySetInnerHTML={{ __html: comparison_html.left }}/>
          <li className="current" dangerouslySetInnerHTML={{ __html: comparison_html.right }}/>
        </ul>
      );
    }
  }
}
