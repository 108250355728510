export const getRequiredMessage = (label = "This") => `${label} is required`;

export const getMaxLengthMesssage = (label = "This", maxLength = 0) =>
  `${label} must be ${maxLength} characters or less`;

export const validateUrl = (value) => {
  if (!value) {
    return "URL is required";
  }

  const url = value.toLowerCase();
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  if (!pattern.test(url)) {
    return "URL is invalid";
  }

  return null;
};

export const validateEmail = (email) => {
  const result = String(email ?? "")
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (!result) {
    return "Email is invalid";
  }

  return null;
};

export const validateUrlOrEmpty = (value) => {
  if (value) {
    return validateUrl(value);
  }
  return null;
};

export const validates = (formData, { requiredFields = [] as string[], maxLengths = {}, labels = {} }) => {
  const errorMessages = {} as any;

  for (const key of requiredFields) {
    if (!formData[key]) {
      errorMessages[key] = getRequiredMessage(labels[key]);
    }
  }

  for (const key in maxLengths) {
    if (formData[key] && formData[key].length > maxLengths[key]) {
      errorMessages[key] ||= getMaxLengthMesssage(labels[key], maxLengths[key]);
    }
  }

  return {
    isValid: Object.keys(errorMessages).length === 0,
    errorMessages
  };
};
