import { IndicatorsForTopicAndTrackQuery } from "gql/indicators.graphql";
import { TopicsForTrackQuery } from "gql/topics.graphql";
import { Dropdown, DropdownMenuItemType, IconButton, IDropdownOption } from "office-ui-fabric-react";
import React from "react";
import { firstBy } from "thenby";
import { GQLTrack } from "../../../schemas/schema";
import { gqlQuery } from "../../lib";
import { deep_clone } from "../../utils";
import { DropdownEnhanced } from "../DropdownEnhanced";

interface IProps {
  allTracks: GQLTrack[]
  canDelete: boolean
  item: any
  alterItem: (item: any) => void
}

export class TrackTopicLine extends React.Component<IProps> {
  render() {
    const { allTracks, item } = this.props;
    const trackOptions = allTracks.map((x) => ({ text: x.name, key: x.id })) as IDropdownOption[];

    return (
      <>
        <div className={"cell"}>
          <DropdownEnhanced
            data-testid={"track-select"}
            placeholder={"Select Track..."}
            className={"select"}
            disabled={!!item.rt}
            onChange={(e, value) => {
              if (e.type == "focus") {
                return;
              }
              !Array.isArray(value) &&
                this.props.alterItem({
                  track_id: value.key + "",
                  tier: 1,
                  weight: 1,
                  smes: []
                });
            }}
            options={trackOptions}
            selectedKey={item.track_id ? item.track_id + "" : undefined}
          />
        </div>
        <div className={"cell"}>
          {item.track_id &&
            gqlQuery(TopicsForTrackQuery, { track_id: item.track_id }, (res) => {
              const options = res.topics_for_track
                .filter((topic) => topic.is_enabled || topic.id === item.topic_id)
                .map((topic) => ({ text: topic.name, key: topic.id }));

              return (
                <DropdownEnhanced
                  data-testid={"topic-select"}
                  placeholder={"Select Topic..."}
                  className={"select"}
                  onChange={(e, value) => {
                    if (e.type == "focus") {
                      return;
                    }
                    !Array.isArray(value) &&
                      this.props.alterItem({
                        topic_id: value.key,
                        indicators: []
                      });
                  }}
                  options={options}
                  selectedKey={item.topic_id ? item.topic_id + "" : undefined}
                />
              );
            })}
        </div>
        <div className={"cell"}>
          {item.track_id &&
            item.topic_id &&
            gqlQuery(IndicatorsForTopicAndTrackQuery, { track_id: item.track_id, topic_id: item.topic_id }, (res) => (
              <Dropdown
                data-testid={"indicator-select"}
                placeholder={"Select Indicators..."}
                className={"select"}
                multiSelect
                onChange={(e, value) => {
                  if (e.type == "focus" || !value) {
                    return;
                  }
                  let indicators;
                  if (value.selected) {
                    indicators = ([] as any[]).concat(value.key, ...item.indicators);
                  } else {
                    item.indicators.splice(item.indicators.indexOf(value.key), 1);
                    indicators = item.indicators;
                  }
                  this.props.alterItem({
                    indicators
                  });
                }}
                options={this.indicators_for_topic_track_options(res)}
                selectedKeys={item.indicators.map((x) => x + "")}
              />
            ))}
        </div>
        {this.props.canDelete && (
          <IconButton
            className={"btn-delete"}
            iconProps={{ iconName: "trash" }}
            onClick={() => this.props.alterItem({ delete: true })}
          />
        )}
      </>
    );
  }

  indicators_for_topic_track_options(res: any): IDropdownOption[] {
    const out = [] as IDropdownOption[];
    const tiers_added = new Set<string>();
    let indicators_for_topic_track = deep_clone(res.indicators_for_topic_track);
    indicators_for_topic_track = indicators_for_topic_track.sort(firstBy((x: any) => x.tier.id));
    indicators_for_topic_track.forEach((ind) => {
      const tier_name = ind.tier.name;
      if (!tiers_added.has(tier_name)) {
        out.push({ key: tier_name, text: tier_name, itemType: DropdownMenuItemType.Header });
        tiers_added.add(tier_name);
      }
      out.push({ key: ind.id, text: ind.description });
    });
    return out;
  }
}
