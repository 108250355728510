export const FollowUpTypePolicies = {
  FollowUpItem: {
    fields: {
      reference_id: (_, { readField, toReference }) => {
        const objectId = readField("object_id");
        const objectType = readField("object_type");

        const object = toReference({ __typename: objectType, id: objectId });
        return readField("reference_id", object);
      }
    }
  },
  ReportRecommendation: {
    fields: {
      reference_id: (_, { readField, toReference }) => {
        const id = readField("report_finding_id");
        const reportFinding = toReference({ __typename: "ReportFinding", id });
        return readField("reference_id", reportFinding);
      }
    }
  }
};
