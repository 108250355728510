import gql from "graphql-tag";

export const SimpleCurrencyFragment = gql`
  fragment SimpleCurrencyFragment on Currency {
    key
    name
    unit
  }
`;

export const CurrencyFragment = gql`
  ${SimpleCurrencyFragment}
  fragment CurrencyFragment on Currency {
    ...SimpleCurrencyFragment
    thousands_indicator
    millions_indicator
    billions_indicator
    ranges
  }
`;
