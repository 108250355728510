import PubSub from "pubsub-js";
import BaseComponent from "../../reports/BaseComponent";

export class ExpandableComponent<Props> extends BaseComponent<Props, { expanded: boolean }> {
  state = {
    expanded: false
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  private unsub: any;
  constructor(props) {
    super(props);
    // @ts-expect-error
    this.state.expanded = window.expanded;
  }

  componentDidMount() {
    this.unsub = PubSub.subscribe("client_report.expand-all", (e, v) => {
      this.setState({ expanded: v });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.unsub);
  }
}
