import * as ActionCable from "actioncable";

export default class LockedPaths {
  _data = {} as { string: string };
  private readonly keys: Set<string> = new Set();
  private readonly localLocks: Set<string> = new Set();
  private readonly sub: ActionCable.Channel;
  private readonly lock_queue = new Set();
  private readonly unlock_queue = new Set();
  private commit_timeout: number;

  constructor(sub: ActionCable.Channel) {
    this.sub = sub;
    PubSub.subscribe("cable.message", (msg, message) => {
      if (message.path == "locked_paths") {
        this.data = message.data.data;
      }
    });
  }

  get data(): { string: string } {
    return this._data;
  }

  set data(val) {
    // This can be optimized, some double rendering can happen
    this._data = val;
    const newKeys = new Set(Object.keys(val));
    this.keys.forEach((key) => {
      if (!newKeys.has(key)) {
        this.keys.delete(key);
        PubSub.publish(`lock_update.${key}`, {});
      }
    });
    newKeys.forEach((key) => {
      if (this.keys.has(key)) {

      } else {
        this.keys.add(key);
        if (!this.localLocks.has(key)) PubSub.publish(`lock_update.${key}`, {});
      }
    });
  }

  isLocked(key: string): boolean {
    return !this.localLocks.has(key) && this.keys.has(key); // return true if it's locked but not locally
  }

  addLocalLock(key: string) {
    this.localLocks.add(key);
  }

  removeLocalLock(key: string) {
    this.localLocks.delete(key);
  }

  getLockUser(key: string) {
    // @ts-expect-error
    return this.data[key];
  }

  commit() {
    clearTimeout(this.commit_timeout);
    this.commit_timeout = window.setTimeout(() => {
      if (this.lock_queue.size) {
        console.warn(this.lock_queue);
        this.sub.send({ lock: Array.from(this.lock_queue) });
        this.lock_queue.clear();
      }
      if (this.unlock_queue.size) {
        console.warn(this.unlock_queue);
        this.sub.send({ unlock: Array.from(this.unlock_queue) });
        this.unlock_queue.clear();
      }
    }, 500);
  }

  lockPath(path: string) {
    this.lock_queue.add(path);
    this.addLocalLock(path);
    this.commit();
  }

  unlockPath(path: string) {
    this.unlock_queue.add(path);
    this.removeLocalLock(path);
    this.commit();
  }
}
