import * as React from "react";
import BaseComponent from "../../../reports/BaseComponent";
import { LoadingScreen } from "../LoadingScreen";

export class MutationStep extends BaseComponent<{
  name: string
  mutation: any
  variables?: any
  onComplete?: (...a: any[]) => void
}> {
  static defaultProps: {
    onComplete: () => null
    variables: {}
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return this.props.name != nextProps.name;
  }

  componentDidMount() {
    this.mutate();
  }

  componentDidUpdate() {
    this.mutate();
  }

  mutate() {
    window.apolloClient
      .mutate({
        mutation: this.props.mutation,
        variables: this.props.variables
      })
      .then(this.props.onComplete);
  }

  render() {
    return <LoadingScreen type={"shimmer"} />;
  }
}
