import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { showIf } from "../lib";

export class ScrollToTopWidget extends React.Component {
  state = { scrollY: 0 };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    this.setState({ scrollY: window.scrollY });
  };

  scrollToTop = () => {
    window.scrollTo(window.scrollX, 0);
  };

  render() {
    return (
      <div {...showIf(this.state.scrollY >= 200)} className={"scroll-to-top-widget"} onClick={this.scrollToTop}>
        <FontAwesomeIcon icon={["fal", "chevron-up"]} size={"2x"} inverse />
      </div>
    );
  }
}
