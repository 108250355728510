import React from "react";
import { HistoryComponentBase, HistoryComponentProps } from "./HistoryComponentBase";
import { friendlyDateTime } from "@/admin/utils";
import { GQLUser } from "@/schemas/schema";

interface EventHistoryProps extends HistoryComponentProps {
  timestamp: string
  user: GQLUser
  event: string
  object_name: string
}

export class EventHistory extends HistoryComponentBase<EventHistoryProps> {
  render(): JSX.Element | null {
    const { timestamp, user, title, event, object_name } = this.props;
    const verb = event === "create" ? "created" : "removed";

    return (
      <div className="diff-modal-inside">
        <div className="version">
          <div className="desc">
            <strong>{user?.full_name}</strong> {verb} the <strong>{title}</strong> {object_name}
          </div>
          <div className="timestamp">{friendlyDateTime(timestamp)}</div>
          <div className="clear" />
          <div>{this.renderInner()}</div>
        </div>
      </div>
    );
  }
}
