import { GQLUser } from "../../../schemas/schema";
import { ObjectSelector } from "../ObjectSelector";

export class PersonSelectorBase extends ObjectSelector {
  sortObjects(users) {
    return users.sort((a, b) => (a.full_name || "").localeCompare(b.full_name || ""));
  }

  getLabel(option: GQLUser) {
    return option.full_name;
  }
}
