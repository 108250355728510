import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import { ExpandableComponent } from "../../../client/components";
import "./styles.scss";

interface ITogglePanelProps {
  expanded: boolean
  onToggle: () => void
  variant?: string
}

interface ICollapsablePanelProps {
  expanded: boolean
  onToggle?: (expanded) => void
  children: JSX.Element[]
  deps?: any
  variant?: string
}

const CollapsablePanelContext = React.createContext({
  expanded: false,
  onToggle: () => {},
  variant: undefined as string | undefined
});

export class CollapsablePanel extends ExpandableComponent<ICollapsablePanelProps> {
  constructor(props) {
    super(props);
    this.state.expanded = this.props.expanded;
  }

  componentDidUpdate(prevProps) {
    if (this.props.expanded != prevProps.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  onToggle = () => {
    this.setState((prevState) => {
      if (this.props.onToggle) {
        this.props.onToggle(!prevState.expanded);
      }
      return { expanded: !prevState.expanded };
    });
  };

  render() {
    const { children, variant } = this.props;
    const variantClassName = variant ? `variant-${variant}` : "";

    return (
      <div className={`collapsable-panel ${this.state.expanded ? "expanded" : ""} ${variantClassName}`}>
        <CollapsablePanelContext.Provider value={{ expanded: this.state.expanded, onToggle: this.onToggle, variant }}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && (child as React.ReactElement<any>).type === CollapsablePanel.Title) {
              return child;
            } else {
              return null;
            }
          })}
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && (child as React.ReactElement<any>).type === CollapsablePanel.Body) {
              return child;
            } else {
              return null;
            }
          })}
        </CollapsablePanelContext.Provider>
      </div>
    );
  }
}

export namespace CollapsablePanel {
  export class Title<Props> extends React.Component<Props & { className?: string }> {
    static contextType = CollapsablePanelContext;

    render() {
      const { expanded, onToggle, variant } = this.context;
      const { className } = this.props;

      return (
        <div className={cn("titlebar-outer", className)} onClick={onToggle} data-testid="collapsable-panel-title">
          <div className="titlebar-inner">{this.props.children}</div>
          <CollapsablePanel.TogglePanel expanded={expanded} onToggle={onToggle} variant={variant} />
        </div>
      );
    }
  }
}

export namespace CollapsablePanel {
  export class Body<Props> extends React.Component<Props & { className?: string }> {
    static contextType = CollapsablePanelContext;

    render() {
      const { expanded } = this.context;
      const { className } = this.props;

      return expanded && <div className={cn("collapsable-panel-body", className)}>{this.props.children}</div>;
    }
  }
}

export namespace CollapsablePanel {
  export class TogglePanel extends React.Component<ITogglePanelProps> {
    constructor(props) {
      super(props);
    }

    render() {
      const { expanded, onToggle, variant } = this.props;
      const iconType = variant === "cms" ? "caret" : "chevron";
      const iconPrefix = variant === "cms" ? "fas" : "fal";

      return (
        <div className="action-toggle-panel">
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            size={"lg"}
            // @ts-expect-error
            icon={[iconPrefix, expanded ? `${iconType}-up` : `${iconType}-down`]}
          />
        </div>
      );
    }
  }
}
