import { useCallback, useState } from "react";

export const CharacterLimitAlgos = {
  default: (textContent, _) => textContent.length,
  weighted: (bulletPointValue = 50, lineBreakValue = 10) => {
    return (textContent: string, htmlContent: string) => {
      const bulletPoints = htmlContent.match(/<li[^>]*>/g) ?? [];
      const paragraphBreaks = htmlContent.match(/<p[^>]*>/g) ?? [];
      if (htmlContent.match(/<\/p>$/)) {
        paragraphBreaks.pop(); // last paragraph break is not counted
      } else if (htmlContent.match(/<\/[u|o]l>$/)) {
        bulletPoints.pop();
      }

      return (bulletPoints.length * bulletPointValue) +
          (paragraphBreaks.length * lineBreakValue) +
          textContent.length;
    };
  }
};

export const useCharCount = (config, editorRef, characterLimit = -1, characterLimitAlgo = CharacterLimitAlgos.default) => {
  const [charCount, setCharCount] = useState(0);
  const charLimitEnabled = characterLimit > 0;

  const charCountConfig = {
    charCounterCount: true,
    events: {
      ...config.events,
      "charCounter.update": function() {
        setCharCount(contentLength());
      }
    }
  };

  const contentLength = useCallback(() => {
    const editor = editorRef.current && editorRef.current.getEditor();

    if (editor) {
      const textContent = (editor.el.textContent || "").replace(/\u200B/g, "");
      const htmlContent = editor.html.get();
      return characterLimitAlgo(textContent, htmlContent);
    } else {
      return 0;
    }
  }, [editorRef.current]);

  const isCharLimitExceeded = () => {
    return charLimitEnabled && charCount > characterLimit;
  };

  return { charLimitEnabled, charCountConfig, charCount, isCharLimitExceeded };
};
