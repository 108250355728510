import ActionCable from "actioncable";
import PubSub from "pubsub-js";

export default class ActionCableBase {
  protected sub: ActionCable.Channel;
  protected cable: ActionCable.Cable;
  protected channel_name = "";

  connect(args: any): void {
    this.cable = ActionCable.createConsumer("/cable");

    this.sub = this.cable.subscriptions.create({
      channel: this.channel_name,
      ...args
    }, {
      connected: () => this.connected(args),
      disconnected: this.disconnected,
      received: msg => this.receiveMessage(msg)
    });
  }

  connected(args: any): void {
    PubSub.publish("channel.connected", args);
    window.document.dispatchEvent(new Event("channel.connected", args));
    console.info("Connected to channel", args);
  }

  disconnected(): void {
    console.info("Disconnected from channel");
  }

  receiveMessage(msg): void {
    console.info("Received message", msg);
  }
}
