import { Label, TextField } from "office-ui-fabric-react";
import React from "react";
import { withTooltip } from "../../lib";
import "./MaskedField.scss";

export type LayoutType = "horizontal" | "vertical";

export interface MaskedFieldProps {
  layout?: LayoutType
  tooltipIcon?: any
  tooltip?: string
  label?: string
  value: string
  disabled?: boolean
  dataTestId: string
  placeholder?: string
  onChange: (e, val) => void
}

export class MaskedField<P extends MaskedFieldProps> extends React.Component<P> {
  state = { focused: false };

  protected value(): string {
    return this.props.value;
  }

  renderVertical() {
    return (
      <>
        <div>
          {this.props.label &&
            <Label>
              {this.props.label}
              {this.props.tooltip && withTooltip(this.props.tooltip, this.props.tooltipIcon)}
            </Label>}
        </div>
        <div>
          <TextField
            value={this.value()}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() => {
              this.setState({ focused: false });
            }}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            data-testid={this.props.dataTestId}
            disabled={this.props.disabled}
          />
        </div>
      </>
    );
  }

  renderHorizontal() {
    return (
      <div className="masked-field-container">

        {this.props.label &&
          <div className="label-container">
            <Label className="label">
              {this.props.label}
              {this.props.tooltip && withTooltip(this.props.tooltip, this.props.tooltipIcon)}
            </Label>
          </div>}
        <div className="field-container">
          <TextField
            value={this.value()}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() => {
              this.setState({ focused: false });
            }}
            onChange={this.props.onChange}
            data-testid={this.props.dataTestId}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    );
  }

  render() {
    const { layout = "vertical" } = this.props;

    return layout === "vertical" ? this.renderVertical() : this.renderHorizontal();
  }
}
