import { cloneDeep, isArray, mergeWith } from "lodash";

const PRO_WRITING_AID_KEY = import.meta.env.VITE_PWA_KEY || "F3369B13-160D-417B-901D-4C9EB082C99F";

export const buildGrammarCheckConfig = () => (
  {
    spellcheck: false, // switch off internal spellcheck for avoid conflicts
    bgOptions: {
      service: {
        apiKey: PRO_WRITING_AID_KEY
      },
      grammar: {
        languageFilter: ["en-US", "en-GB"],
        languageIsoCode: "en-US",
        checkStyle: true,
        checkSpelling: true,
        checkGrammar: true,
        showThesaurusByDoubleClick: true,
        showContextThesaurus: true
      }
    }
  }
);

export const buildImagePluginConfig = (objectReference, fieldName) => (
  {
    imageMaxSize: 1024 * 1024 * 5,
    imageOutputSize: true,
    imagePaste: true,
    imageDefaultAlign: "left",
    imageDefaultWidth: "500"
  }
);

const COLOR_RED = "#F14827";
const COLOR_ORANGE = "#F4A806";
const COLOR_YELLOW = "#ecd16a";
const COLOR_GREEN = "#6d973e";
const COLOR_PURE_YELLOW = "#FFFF00";

const BACKGROUND_COLORS = [COLOR_PURE_YELLOW, "REMOVE"];
const TEXT_COLORS = [COLOR_RED, COLOR_ORANGE, COLOR_YELLOW, COLOR_GREEN, "REMOVE"];

const PASTE_DENIED_TAGS = [
  "abbr", "address", "area", "article", "aside", "audio",
  "base", "bdi", "bdo", "button", "canvas",
  "caption", "cite", "col", "colgroup", "datalist", "dd",
  "del", "details", "dfn", "dialog", "div", "dl", "dt",
  "embed", "fieldset", "figcaption", "figure", "footer", "form",
  "header", "hgroup",
  "iframe", "img", "input", "ins", "kbd", "keygen", "label",
  "legend", "link", "main", "map", "mark", "menu", "menuitem",
  "meter", "nav", "noscript", "object", "optgroup", "option",
  "output", "param", "pre", "progress", "queue", "rp", "rt", "ruby",
  "samp", "script", "style", "section", "select", "small", "source",
  "span", "strike", "sub", "summary", "sup", "table", "tbody",
  "td", "textarea", "tfoot", "th", "thead", "time", "tr",
  "track", "var", "video", "wbr"
];

export const FROALA_BASE_CONFIG = {
  pluginsEnabled: ["lists", "wordPaste", "colors", "inlineStyle", "url"],
  imagePaste: false,
  pasteDeniedTags: PASTE_DENIED_TAGS,
  pasteAllowedStyleProps: [],
  wordAllowedStyleProps: [],
  wordDeniedTags: PASTE_DENIED_TAGS,
  imageEditButtons: ["imageAlign", "imageDisplay", "imageSize", "imageRemove"],
  colorsBackground: BACKGROUND_COLORS,
  colorsText: TEXT_COLORS,
  colorsHEXInput: false,
  linkAlwaysBlank: true,
  listAdvancedTypes: false,
  toolbarButtons: {
    moreText: {
      buttons: ["bold", "italic", "strikeThrough", "textColor", "backgroundColor"],
      buttonsVisible: 6
    },

    moreRich: {
      buttons: ["insertLink"],
      buttonsVisible: 3
    },

    moreParagraph: {
      buttons: ["formatUL", "formatOL"],
      buttonsVisible: 2
    },

    moreMisc: {
      buttons: ["undo", "redo"],
      align: "right",
      buttonsVisible: 2
    }
  }
};

const noTextColorModifier = (config): object => (
  {
    toolbarButtons: {
      moreText: {
        buttons: config.toolbarButtons.moreText.buttons.filter((button) => button != "textColor")
      }
    }
  }
);

const noStrikeThroughModifier = (config): object => (
  {
    toolbarButtons: {
      moreText: {
        buttons: config.toolbarButtons.moreText.buttons.filter((button) => button != "strikeThrough")
      }
    }
  }
);

const noBackgroundColorModifier = (config): object => (
  {
    toolbarButtons: {
      moreText: {
        buttons: config.toolbarButtons.moreText.buttons.filter((button) => button != "backgroundColor")
      }
    }
  }
);

const allButtonsFloatLeftModifier = (config): object => (
  {
    toolbarButtons: {
      moreMisc: {
        ...config.toolbarButtons.moreMisc,
        align: "left"
      }
    }
  }
);

const inModalModifier = (config): object => (
  {
    zIndex: 1000000,
    scrollableContainer: "div.ms-Dialog-main"
  }
);

const withTablesModifier = (config): object => (
  {
    pluginsEnabled: [...config.pluginsEnabled, "table"],
    toolbarButtons: {
      moreRich: {
        buttons: [
          ...config.toolbarButtons.moreRich.buttons,
          "insertTable"
        ],
        buttonsVisible: config.toolbarButtons.moreRich.buttonsVisible + 1
      }
    },
    tableEditButtons: [
      "tableHeader", "tableCellVerticalAlign", "tableCellHorizontalAlign",
      "-", "tableRows", "tableColumns", "tableRemove"
    ]
  }
);

const withHeadingsModifier = (config): object => (
  {
    pluginsEnabled: [...config.pluginsEnabled, "paragraphFormat"],
    toolbarButtons: {
      moreRich: {
        buttons: [
          ...config.toolbarButtons.moreRich.buttons,
          "paragraphFormat"
        ],
        buttonsVisible: config.toolbarButtons.moreRich.buttonsVisible + 1
      }
    },
    paragraphFormat: {
      N: "Normal",
      H4: "Heading 1",
      H5: "Heading 2"
    }
  }
);

export type FroalaConfigModifier = "noTextColor" | "noBackgroundColor" | "noStrikeThrough" |
"allButtonsFloatLeft" | "inModal" | "withTables" | "withHeadings";

export const FroalaConfigModifiers = {
  noTextColor: "noTextColor" as FroalaConfigModifier,
  noBackgroundColor: "noBackgroundColor" as FroalaConfigModifier,
  noStrikeThrough: "noStrikeThrough" as FroalaConfigModifier,
  allButtonsFloatLeft: "allButtonsFloatLeft" as FroalaConfigModifier,
  inModal: "inModal" as FroalaConfigModifier,
  withTables: "withTables" as FroalaConfigModifier,
  withHeadings: "withHeadings" as FroalaConfigModifier
};

const PRESET_MODIFIERS = {
  [FroalaConfigModifiers.noTextColor]: noTextColorModifier,
  [FroalaConfigModifiers.noBackgroundColor]: noBackgroundColorModifier,
  [FroalaConfigModifiers.noStrikeThrough]: noStrikeThroughModifier,
  [FroalaConfigModifiers.allButtonsFloatLeft]: allButtonsFloatLeftModifier,
  [FroalaConfigModifiers.inModal]: inModalModifier,
  [FroalaConfigModifiers.withTables]: withTablesModifier,
  [FroalaConfigModifiers.withHeadings]: withHeadingsModifier
};

const applyPreset = (config, preset: FroalaConfigModifier): object => {
  const presetModifier = PRESET_MODIFIERS[preset];

  if (presetModifier) {
    return mergeConfig(config, presetModifier(config));
  } else {
    return config;
  }
};

const mergeConfig = (config, modification): object => {
  const customizer = (target, mod): any => {
    if (isArray(target) && isArray(mod)) {
      return mod;
    } else {
      return undefined;
    }
  };
  return mergeWith(cloneDeep(config), modification, customizer);
};

interface FroalaConfig {
  pluginsEnabled: string[]
  toolbarButtons: any
}

export const baseConfigWithModifiers = (presets: FroalaConfigModifier[]): FroalaConfig => {
  return presets.reduce(applyPreset, FROALA_BASE_CONFIG);
};
