import React from "react";
import { friendlyDateTime } from "../../../../admin/utils";
import { GQLUser } from "../../../../schemas/schema";

export interface HistoryComponentProps {
  timestamp: string
  user: GQLUser
  title: string
}

export class HistoryComponentBase<P extends HistoryComponentProps> extends React.Component<P> {
  verb = "updated";

  render(): JSX.Element | null {
    const { timestamp, user, title } = this.props;

    return (
      <div className="diff-modal-inside">
        <div className="version">
          <div className="desc">
            <strong>{user.full_name}</strong> {this.verb} the <strong>{title}</strong>
          </div>
          <div className="timestamp">{friendlyDateTime(timestamp)}</div>
          <div className="clear" />
          <div>{this.renderInner()}</div>
        </div>
      </div>
    );
  }

  renderInner(): JSX.Element | null {
    return null;
  }
}
