import CodeMirror from "codemirror";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/mode/overlay.js";
import "codemirror/keymap/vim.js";
import "codemirror/theme/3024-day.css";
import "codemirror/theme/3024-night.css";
import "codemirror/theme/abcdef.css";
import "codemirror/theme/ambiance.css";
import "codemirror/theme/ayu-dark.css";
import "codemirror/theme/ayu-mirage.css";
import "codemirror/theme/base16-dark.css";
import "codemirror/theme/base16-light.css";
import "codemirror/theme/bespin.css";
import "codemirror/theme/blackboard.css";
import "codemirror/theme/cobalt.css";
import "codemirror/theme/colorforth.css";
import "codemirror/theme/darcula.css";
import "codemirror/theme/dracula.css";
import "codemirror/theme/duotone-dark.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/theme/eclipse.css";
import "codemirror/theme/elegant.css";
import "codemirror/theme/erlang-dark.css";
import "codemirror/theme/gruvbox-dark.css";
import "codemirror/theme/hopscotch.css";
import "codemirror/theme/icecoder.css";
import "codemirror/theme/idea.css";
import "codemirror/theme/isotope.css";
import "codemirror/theme/lesser-dark.css";
import "codemirror/theme/liquibyte.css";
import "codemirror/theme/lucario.css";
import "codemirror/theme/material-darker.css";
import "codemirror/theme/material-ocean.css";
import "codemirror/theme/material-palenight.css";
import "codemirror/theme/material.css";
import "codemirror/theme/mbo.css";
import "codemirror/theme/mdn-like.css";
import "codemirror/theme/midnight.css";
import "codemirror/theme/monokai.css";
import "codemirror/theme/moxer.css";
import "codemirror/theme/neat.css";
import "codemirror/theme/neo.css";
import "codemirror/theme/night.css";
import "codemirror/theme/nord.css";
import "codemirror/theme/oceanic-next.css";
import "codemirror/theme/panda-syntax.css";
import "codemirror/theme/paraiso-dark.css";
import "codemirror/theme/paraiso-light.css";
import "codemirror/theme/pastel-on-dark.css";
import "codemirror/theme/railscasts.css";
import "codemirror/theme/rubyblue.css";
import "codemirror/theme/seti.css";
import "codemirror/theme/shadowfox.css";
import "codemirror/theme/solarized.css";
import "codemirror/theme/the-matrix.css";
import "codemirror/theme/tomorrow-night-bright.css";
import "codemirror/theme/tomorrow-night-eighties.css";
import "codemirror/theme/ttcn.css";
import "codemirror/theme/twilight.css";
import "codemirror/theme/vibrant-ink.css";
import "codemirror/theme/xq-dark.css";
import "codemirror/theme/xq-light.css";
import "codemirror/theme/yeti.css";
import "codemirror/theme/yonce.css";
import "codemirror/theme/zenburn.css";

CodeMirror.getUserSettings = () => {
  const { current_user } = window;
  if (!current_user) {
    return {};
  }
  const opts = {};
  opts.theme = "default";

  return opts;
};

CodeMirror.defineMode("corsis", function(cmCfg, modeCfg) {
  const mode = CodeMirror.getMode(cmCfg, "markdown");
  const originalToken = mode.token;
  mode.token = (stream, state) => {
    if (typeof state !== "object") {
      state = {};
    }
    if (state.inFunction || stream.match(/\[\[.*/, false)) {
      state.inFunction = true;
      let cur;
      const ch = stream.next();
      if (ch == "]") {
        if (state.closing) {
          state.inFunction = false;
          state.nameDefined = null;
          state.closing = false;
          return "function";
        }
        state.closing = true;
      }
      if (ch === "[" || ch === "]") {
        return "function";
      }
      if (!state.nameDefined && ch !== " ") {
        state.nameDefined = ch + stream.match(/[^ |\]]*/)[0];
        return "function-name";
      }
      if (state.nameDefined && ch !== " ") {
        return "parameter";
      }
      if (ch === " ") {
        return null;
      }
      return "function";
    }
    return originalToken(stream, state);
  };
  return mode;
});

const CODE_HINTS = {};
if (window.constants) {
  const { CORSIS_MARKDOWN } = window.constants;
  for (const tag of Object.keys(CORSIS_MARKDOWN || {})) {
    if (CORSIS_MARKDOWN[tag] && CORSIS_MARKDOWN[tag].parameters) {
      CODE_HINTS[tag] = Object.keys(CORSIS_MARKDOWN[tag].parameters);
    } else {
      CODE_HINTS[tag] = [];
    }
  }
}
function _currentClose(line, chpos): number {
  const subst = line.substr(chpos);
  const nextOpen = subst.indexOf("[[");
  const nextClose = subst.indexOf("]]");
  let targetPos: number;
  if (nextOpen > nextClose) {
    targetPos = nextOpen;
  } else if (nextClose > -1) {
    targetPos = nextClose;
  } else {
    targetPos = subst.trim().length;
  }
  return targetPos + chpos;
}

const WORD = /[\w$]+/g;

function getHints(cm, options) {
  const addSpace = (x) => x + " ";
  const addTagMarkers = (x) => `[[ ${x} ]] `;
  let list = [] as string[];
  const cur = cm.getCursor();
  let start = cur.ch;
  let end = start;
  const token = cm.getTokenAt(cur);
  if (token.state.inFunction) {
    const functionName = token.state.nameDefined || "";
    const replacesFunction = !CODE_HINTS[token.state.nameDefined];
    list = (
      CODE_HINTS[token.state.nameDefined] || Object.keys(CODE_HINTS).filter((x) => x.startsWith(functionName))
    ).map(addSpace);
    if (cur.ch !== " ") {
      let closeTag;
      const line = cm.getLine(cur.line);
      if (replacesFunction) {
        closeTag = cur.ch - functionName.length;
        let n = functionName.length;
        while (n--) {
          cm.execCommand("delCharBefore");
        }
      } else {
        closeTag = line.lastIndexOf("]]");
        if (closeTag == -1) closeTag = line.length;
      }
      if (line.substr(closeTag - 1, 1) != " ") {
        list = list.map((x) => " " + x);
      }
      const word = (options && options.word) || WORD;
      const curLine = cm.getLine(cur.line);
      (start = cur.ch), (end = start);
      while (end < curLine.length && word.test(curLine.charAt(end))) ++end;
      while (start && word.test(curLine.charAt(start - 1))) --start;
      if (replacesFunction) {
        start = end = cur.ch;
      } else {
        start++;
        list = list.map((x) => " " + x);
      }
    }
  } else {
    list = Object.keys(CODE_HINTS).map(addTagMarkers);
  }
  return { list, from: CodeMirror.Pos(cur.line, start), to: CodeMirror.Pos(cur.line, end) };
}

CodeMirror.registerHelper("hint", "corsis", getHints);

function getLineAndChar(content, tag) {
  const lineNumber = (content.substr(0, tag.startPos).match(/\n/g) || []).length;
  const pos = tag.startPos - content.substr(0, tag.startPos).lastIndexOf("\n");
  const startPos = pos;
  const endPos = startPos + (tag.endPos - tag.startPos);
  return [lineNumber, startPos - 1, endPos - 1];
}

class IgnoreList {
  private readonly data: Set<string>;
  private key = "grammar.ignoreList";
  private readonly limit = 600;

  constructor() {
    let data = [];
    const store = localStorage.getItem(this.key);
    if (store) {
      data = JSON.parse(store);
    }
    this.data = new Set(data);
  }

  async store() {
    localStorage[this.key] = JSON.stringify(Array.from(this.data));
  }

  has(item) {
    return this.data.has(item);
  }

  async add(str) {
    this.data.add(str);
    if (this.data.size > this.limit) {
      this.data.delete(this.data[0]);
    }
  }
}

const ignoreList = new IgnoreList();

function grammarCheck(cm) {
  if (typeof cm.getGrammar !== "function") {
    return;
  }

  cm.getGrammar().then((tags) => {
    // @ts-expect-error
    const runner = (func) => (window.requestIdleCallback ? window.requestIdleCallback(func) : func());
    runner(() => {
      const content = cm.getValue();
      cm.getAllMarks().forEach((mark) => {
        if (!mark.className.startsWith("cm-grammar")) return;
        mark.clear();
      });
      for (const tag of tags) {
        if (ignoreList.has(`${tag.category}::${tag.subcategory}`)) {
          continue;
        }
        const [line, start, end] = getLineAndChar(content, tag);
        cm.markText(
          { line, ch: start },
          { line, ch: end + 1 },
          {
            className: `cm-grammar cm-${tag.report} cm-grammar-id${tag.id}`
          }
        );
      }
    });
  });
}

CodeMirror.defineOption("grammarCheck", [], function(cm, value, prev) {
  const checkTimeout = 3000;
  cm.on("inputRead", function(cm1, change) {
    if (cm1.grammarCheckTimeout) {
      clearTimeout(cm1.grammarCheckTimeout);
    }
    cm1.grammarCheckTimeout = setTimeout(() => grammarCheck(cm1), checkTimeout);
  });
  cm.on("grammar-init", (cm1) => grammarCheck(cm1));
  cm.on("grammar-ignore", ([cm1, tag]) => {
    ignoreList.add(`${tag.category}::${tag.subcategory}`);
    ignoreList.store();
    grammarCheck(cm1);
  });
});

export default CodeMirror;
