import React from "react";

interface IProps {
  selected: number
  total: number
  children?: JSX.Element
}

export const SelectedReportElements = ({ selected, total, children }: IProps) => {
  return (
    <p className="nselected">
      {`${selected} of ${total} selected`}
      {children}
    </p>
  );
};
