import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefaultButton, ICommandBarItemProps, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { CommentsTag } from "../components";

export interface IBottomBarCommentProps {
  model: any
  field: string
}

export interface BottomBarProps {
  onSubmit?: () => void
  onClose?: () => void
  onDiscard?: () => void
  editMode?: boolean
  suggestLabel?: string
  pristine?: boolean
  saveDisabled?: boolean
  functions: {
    editMode?: () => void
    toggleComments: (type: string) => void
    editSuggestion?: () => void
  }
  commentProps?: IBottomBarCommentProps
}

export class BottomBar<Props extends BottomBarProps> extends React.Component<Props> {
  get bottomBarItems() {
    const { editMode, functions } = this.props;
    const out = [] as ICommandBarItemProps[];
    if (!editMode && functions.editMode) {
      out.push({
        key: "edit",
        name: "Edit",
        onClick: () => functions.editMode && functions.editMode(),
        iconName: "pencil"
      });
    }
    if (this.props.suggestLabel) {
      out.push({
        key: "edit-suggestion",
        name: this.props.suggestLabel,
        disabled: !this.props.functions.editSuggestion,
        onClick: () => this.props.functions.editSuggestion!(),
        iconName: "file"
      });
    }
    out.push({
      key: "comment",
      name: "Comments",
      onClick: () => this.props.functions.toggleComments("all"),
      iconName: "comment",
      className: "px-0"
    });

    return out;
  }

  renderButtons() {
    const { onSubmit, onDiscard, onClose, pristine, saveDisabled } = this.props;
    return (
      <>
        {onSubmit && (
          <PrimaryButton
            name="Save"
            disabled={pristine || saveDisabled}
            onClick={() => onSubmit()}
            data-testid="save-on-bottom-bar"
          >
            Save
          </PrimaryButton>
        )}
        {onDiscard && (
          <PrimaryButton name="Discard" disabled={pristine} onClick={onDiscard} data-testid="discard-on-bottom-bar">
            Discard
          </PrimaryButton>
        )}
        {onClose && (
          <DefaultButton name="Close" onClick={() => onClose()} data-testid="close-on-bottom-bar">
            Close
          </DefaultButton>
        )}
      </>
    );
  }

  render() {
    const { editMode, commentProps, functions } = this.props;

    return (
      <div className="bottom-bar">
        <div className="icons flex-1 flex items-stretch pl-24">
          {this.bottomBarItems.map((item) => (
            <button
              key={item.key}
              className={item.className}
              onClick={item.onClick}
              data-testid={`bottombar-button-${item.key}`}
            >
              <FontAwesomeIcon className="text-16 px-4" icon={["fal", item.iconName]} color="rgb(0, 120, 212)" />
              <span className="px-8">{item.name}</span>
            </button>
          ))}
          {commentProps && (
            <div className="flex items-center">
              <CommentsTag
                {...commentProps}
                onOpen={functions.toggleComments}
                prefix={<span className="pr-8">&#8211;</span>}
              />
            </div>
          )}
        </div>
        {editMode && <div className="buttons">{this.renderButtons()}</div>}
      </div>
    );
  }
}
