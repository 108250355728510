import React from "react";

export class ErrorDisplayHandler extends React.Component {
  state = {
    error: null as any,
    hasError: false
  };

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Something went wrong.</h1>
          <pre>{this.state.error?.toString()}</pre>
        </>
      );
    }
    return this.props.children;
  }
}
