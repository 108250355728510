import React from "react";
import ReactCodeMirror from "../../../react-codemirror";

interface IProps {
  className?: string
  value?: string | null
  onChange: (v: string) => void
  placeholder?: string
  onKeyDown: (editor, event) => void
}

export class CodeMirrorInput extends React.Component<IProps> {
  editorRef = null as any;

  componentDidMount() {
    if (this.value) {
      this.editorRef.editor?.setValue(this.value);
    }
  }

  componentDidUpdate(prevProps) {
    const currentValue = this.editorRef.editor?.getValue();
    if (currentValue !== this.value) {
      this.editorRef.editor?.setValue(this.value);
    }
  }

  get value(): string {
    return this.props.value || "";
  }

  handleChange = () => {
    const newValue = this.editorRef.editor?.getValue() || "";
    if (this.value !== newValue) {
      this.props.onChange(newValue);
    }
  };

  handleKeyDown = (editor, event) => {
    this.props.onKeyDown(editor, event);
  };

  handleFocus = (editor, event) => {
    const codeMirror = this.editorRef.editor;
    if (codeMirror && this.value) {
      // Initialize the grammar check via `.init()`
      codeMirror.init();
    }
  };

  render() {
    const { className, placeholder } = this.props;

    return (
      <ReactCodeMirror
        className={className}
        ref={(ref) => (this.editorRef = ref)}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        options={{
          theme: "monokai",
          mode: "corsis",
          lineWrapping: true,
          viewPortMargin: Infinity,
          extraKeys: { "Ctrl-Space": "autocomplete" },
          grammarCheck: true,
          placeholder
        }}
        autoScroll
      />
    );
  }
}
