import { DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { logout } from "../../../reports/common";
import { gqlMutation } from "../../lib";
import { Modal } from "../Modal";
import { DocumentNode } from "graphql";

interface IPromptProps {
  prompt_name?: string
  title: string
  text: string
  skipMutation?: DocumentNode
}

export class Prompt extends React.Component<IPromptProps> {
  prompt_name = "";
  primaryClick = () => {};

  renderModal() {
    const { skipMutation } = this.props;
    if (skipMutation) {
      return gqlMutation(skipMutation, (mutateFunc) => this.renderModalInner(mutateFunc));
    }
    return this.renderModalInner(null);
  }

  renderModalInner(mutateFunc = null) {
    const { prompt_name, title, text } = this.props;
    const m = (action, mutateFunc) =>
      mutateFunc({
        variables: { action, prompt_name: prompt_name ?? this.prompt_name }
      });
    const onClose = mutateFunc ? () => m("postpone", mutateFunc) : undefined;
    return (
      <Modal open={true} title={title} className={"prompt"} checkChanges={false} onClose={onClose}>
        <span className={"text"}>{text}</span>
        <div className={"buttons"}>
          {mutateFunc && (
            <>
              <DefaultButton onClick={() => m("skip", mutateFunc)} data-testId="skip-prompt">
                Skip
              </DefaultButton>
              <DefaultButton onClick={() => m("postpone", mutateFunc)} data-testId="postpone-prompt">
                Remind Me Later
              </DefaultButton>
            </>
          )}
          {!mutateFunc && <DefaultButton onClick={() => logout()}>Logout</DefaultButton>}
          <PrimaryButton onClick={this.primaryClick} data-testId="get-started-button">Get Started</PrimaryButton>
        </div>
      </Modal>
    );
  }

  render() {
    return this.renderModal();
  }
}
