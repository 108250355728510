import gql from "graphql-tag";
import { MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { gqlMutation } from "../lib";
import { LoadingScreen } from "./LoadingScreen";
import { Modal } from "./Modal";

export class Request2FA extends React.Component<{ callback: (code: string) => Promise<void>, onClose: () => void }> {
  query = gql`
    mutation trigger {
      trigger_2fa {
        id
        m2fa_method
      }
    }
  `;

  private init = false;
  state = {
    verificationCode: "",
    error: "",
    loading: false
  };

  render() {
    return gqlMutation(this.query, (mutateFunc) => {
      if (!this.init) {
        mutateFunc().then((res) => this.renderInner());
        this.init = true;
      }
      return this.renderInner();
    });
  }

  submit() {
    this.setState({ loading: true });
    this.props
      .callback(this.state.verificationCode)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ error: "That code is not valid.", loading: false }));
  }

  renderInner() {
    const title = "2FA verification required";
    if (this.state.loading) {
      return (
        <Modal open={true} title={title}>
          <LoadingScreen type={"shimmer"} />
        </Modal>
      );
    }
    return (
      <Modal
        open={true}
        title={title}
        buttons={(m) => (
          <>
            {m.createCancelButton()}
            <PrimaryButton onClick={() => this.submit()}>Submit</PrimaryButton>
          </>
        )}
        onClose={() => this.props.onClose()}
      >
        Please enter your 2FA verification code to continue
        {this.state.error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {this.state.error}
          </MessageBar>
        )}
        <form onSubmit={() => this.submit()} style={{ width: "30em" }}>
          <TextField
            className={"m2fa-disable-input"}
            onChange={(e, verificationCode) => this.setState({ verificationCode })}
            autoFocus
          />
        </form>
      </Modal>
    );
  }
}
