import { Dropdown, IDropdownProps } from "office-ui-fabric-react";
import React from "react";
import { firstBy } from "thenby";

interface IProps extends IDropdownProps {
  orderedAlphabetically?: boolean
  disableSelectOnFocus?: boolean
  disallowEmptySelected?: boolean
}

export const sortOptionsAlphabetically = (options) => {
  return options.sort(firstBy("text"));
};

export const DropdownSelect = ({
  selectedKey,
  onChange,
  disableSelectOnFocus,
  disallowEmptySelected,
  orderedAlphabetically,
  options,
  ...props
}: IProps) => {
  const isEmpty = disallowEmptySelected && (selectedKey === undefined || selectedKey === null);
  const value = isEmpty ? null : selectedKey;

  const handleChange = (e, op, index) => {
    if (disableSelectOnFocus && e.type === "focus") {
      return;
    }
    onChange && onChange(e, op, index);
  };

  const processedOptions = orderedAlphabetically ? sortOptionsAlphabetically(options) : options;

  return <Dropdown selectedKey={value} onChange={handleChange} options={processedOptions} {...props} />;
};
