import React from "react";
import { GQLUser } from "../../../schemas/schema";
import { IObjectSelectorPropsBase } from "../interfaces";
import { PersonSelectorBase } from "./PersonSelectorBase";

interface IProps extends IObjectSelectorPropsBase {
  value: GQLUser[]
  onChange: (user: GQLUser[]) => void
  users: GQLUser[]
}

export class PersonSelector extends React.Component<IProps> {
  render() {
    const { users, ...props } = this.props;

    return <PersonSelectorBase {...props} options={users} className="user-selector" />;
  }
}
