import { Field } from "formik";
import { FormikCheckbox, FormikDatePicker, FormikTextField } from "formik-office-ui-fabric-react/dist";
import gql from "graphql-tag";
import moment from "moment";
import "rc-slider/assets/index.css";
import * as React from "react";
import { AdminFormBase } from "../../admin/common/AdminFormBase";
import { ReportUpdate } from "../../fragments";
import { Report } from "../../schemas/Report";
import "./rc-slider.css";

interface Window {
  page_context: {
    tracks: Track[]
    report: Report
    compliance_tags: ComplianceTag[]
  }
  location: Location
  constants: any
}

declare let window: Window;

export class ReportEditForm<P = { report?: Report }> extends AdminFormBase<{ object?: Report }> {
  acceptedFields = ["name", "job_code", "test", "published_at", "client_id", "project_id"];
  protected required_fields = ["name", "job_code"];
  formColSize = 12;
  protected defaultPreview = true;
  protected report: Report;
  get objectName() {
    return "Report";
  }

  protected show_tool_fields = true;

  editMutation = gql`
    ${ReportUpdate}
    mutation update_report($input: ReportAttributes!) {
      update_report(attributes: $input) {
        ...ReportUpdate
      }
    }
  `;

  initialValuesFromObject(initialValues: any, gqlObject: GQLObject) {
    initialValues = super.initialValuesFromObject(initialValues, gqlObject, {});
    if (initialValues.published_at) {
      initialValues.published_at = moment(initialValues.published_at).toDate();
    }
    return initialValues;
  }

  protected renderFormFields(props, res) {
    return (
      <React.Fragment>
        <Field label="Name" name="name" required component={FormikTextField} />
        <Field label="Job code" name="job_code" required component={FormikTextField} />
        <Field label="Published at" name="published_at" required component={FormikDatePicker} />
        <Field label="Test" name="test" required component={FormikCheckbox} />
      </React.Fragment>
    );
  }
}
