import React from "react";
import PubSub from "pubsub-js";

export const SCROLLABLE_VISIBILITY_EVENT_NAME = "ScrollableContext.visibility";

export class ScrollableContext {
  public static ReactContext = React.createContext({} as ScrollableContext);
  public static Provider = ScrollableContext.ReactContext.Provider;
  public static Consumer = ScrollableContext.ReactContext.Consumer;

  private readonly component: React.Component;

  private elementsInView: string[] = [];

  constructor(component: any) {
    this.component = component;
  }

  setIdInView = (id: string, inView: boolean) => {
    if (!inView) {
      this.elementsInView = this.elementsInView.filter((idInView) => idInView !== id);
    } else if (!this.elementsInView.includes(id)) {
      this.elementsInView = [...this.elementsInView, id];
    }
    PubSub.publish(SCROLLABLE_VISIBILITY_EVENT_NAME, { });
  };

  getIdsInView = () => {
    return this.elementsInView;
  };

  subscribeOnIdsInViewChange = (callback: (idsInView: string[]) => void) => {
    const sub = PubSub.subscribe(
      SCROLLABLE_VISIBILITY_EVENT_NAME,
      (_event, { }) => callback(this.getIdsInView())
    );

    return () => PubSub.unsubscribe(sub);
  };
}
