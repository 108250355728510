import gql from "graphql-tag";

export const ReportUpdate = gql`
  fragment ReportUpdate on Report {
    id
    name
    test
    job_code
    published_at
    directed_area_of_focus
    directed_area_of_focus_versions
    executive_summary
    executive_summary_versions
    recommendations_summary
    recommendations_summary_versions
    access_to_management
    reason_for_access_to_management
    access_to_information
    reason_for_access_to_information
    clarity_of_information
    reason_for_clarity_of_information
  }
`;
