import "codemirror/addon/display/placeholder";
import gql from "graphql-tag";
import React from "react";
import { ICodeMirror, UnControlled as ReactCodeMirrorWrapper } from "react-codemirror2";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import CodeMirror from "./corsis-codemirror";
import "./react-codemirror.style.scss";

interface IGrammarTip {
  id: string
  categoryDisplayName: string
  hint: string
  category: string
  paragraph: string
  helpId: string
  isSubTag: boolean
  suggestions: string[]
  report: string
  subcategory: string
}

const GrammarTooltip = ({ anchor, getGrammarData, removeSelf, parent, acceptSuggestion, ignoreSuggestion }) => {
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement] = React.useState(null);
  const { styles, attributes } = usePopper(anchor, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }]
  });

  const tips = getGrammarData() as IGrammarTip[];
  if (tips.length === 0) return <span></span>;
  const [x] = tips;
  return (
    <span>
      {ReactDOM.createPortal(
        <div
          ref={(v: any) => setPopperElement(v)}
          style={styles.popper}
          className={"grammar-popup"}
          {...attributes.popper}
        >
          <div className={"grammar-advice"} key={x.id}>
            {x.suggestions.length > 0 && (
              <>
                {x.suggestions.map((sugg, index) => (
                  <div className={"grammar-choice"} key={sugg} onClick={() => acceptSuggestion(x.id, sugg)}>
                    {index === 0 && (
                      <div className="hint">
                        {x.categoryDisplayName}: {x.hint}
                      </div>
                    )}
                    <div className={"suggestion"}>{sugg}</div>
                  </div>
                ))}
              </>
            )}
            <div className={"grammar-choice"} onClick={() => ignoreSuggestion(x)}>
              {x.suggestions.length === 0 && (
                <div className="hint">
                  {x.categoryDisplayName}: {x.hint}
                </div>
              )}
              <div className={"ignore"}>Ignore</div>
            </div>
          </div>
        </div>,
        parent
      )}
    </span>
  );
};

interface ReactCodeMirrorProps {
  options: any
  className?: string
  onChange?: () => void
  onFocus?: (editor: any, event: any) => void
  onKeyDown?: (editor, event) => void
  onCursorActivity?: () => void
  autoScroll?: boolean
  onLoad?: (cm: any) => void
}

export const grammarQuery = gql`
  query grammarData($value: String!) {
    grammar_check(text: $value) {
      data
    }
  }
`;

async function getGrammarData(value, obj: any) {
  if (!value) {
    return [];
  }
  const response = (await window.apolloClient.query({ query: grammarQuery, variables: { value } })) as any;
  obj.grammarTags = JSON.parse(response.data.grammar_check.data);
  return obj.grammarTags;
}

export default class ReactCodeMirror extends React.Component<ReactCodeMirrorProps> {
  public cm: ICodeMirror;

  get editor() {
    return this.cm;
  }

  private ref: any;
  private elementRefs = {} as any;
  state = {
    elements: [] as JSX.Element[]
  };

  private readonly grammarTags = [] as any[];

  setRef(cm) {
    if (this.cm && this.ref) {
      return;
    }
    const { grammarCheck } = this.props.options;
    this.ref = cm.ref;
    this.cm = cm.editor;
    this.cm.init = () => {
      if (grammarCheck) {
        CodeMirror.signal(this.cm, "grammar-init", this.cm);
      }
    };
    if (grammarCheck) {
      this.enableGrammarCheck();
    }
    if (this.props.onLoad) {
      this.props.onLoad(this.cm);
    }
  }

  getElement() {
    return ReactDOM.findDOMNode(this)!;
  }

  addElement(el) {
    // let elements = [] as any[];
    this.elementRefs = {};
    this.elementRefs[el.className] = el;
    const elements = [el];
    this.setState({ elements });
  }

  removeElement(el_cls) {
    const { elements } = this.state;
    const el = this.elementRefs[el_cls];
    elements.splice(elements.indexOf(el), 1);
    this.setState({ elements });
    this.elementRefs[el_cls] = undefined;
  }

  removeAllElements() {
    for (const el_cls in this.elementRefs) {
      this.removeElement(el_cls);
    }
  }

  enableGrammarCheck() {
    const me = this;
    const getGrammarId = (x) => x.substr("cm-grammar-id".length);

    const getDataFunc = (el) => {
      return function() {
        const grammarIds = el.className
          .split(" ")
          .filter((x) => x.startsWith("cm-grammar-id"))
          .map(getGrammarId);
        return me.grammarTags.filter((x) => grammarIds.indexOf(x.id) != -1);
      };
    };

    let closeTimeout = undefined as any;
    let openTimeout = undefined as any;
    let openTarget = undefined as any;

    const setCloseTimeout = () => {
      if (closeTimeout !== undefined) return;
      closeTimeout = window.setTimeout(() => {
        me.removeAllElements();
        closeTimeout = undefined;
      }, 1000);
    };

    const cancelCloseTimeout = () => {
      if (closeTimeout === undefined) return;
      window.clearTimeout(closeTimeout);
      closeTimeout = undefined;
    };

    const setOpenTimeout = (target) => {
      if (openTarget === target) return;
      if (openTimeout) clearTimeout(openTimeout);
      openTarget = target;
      openTimeout = window.setTimeout(() => {
        me.removeAllElements();
        const grammarTooltip = (
          <GrammarTooltip
            key={target.className}
            anchor={target}
            parent={this.getElement()}
            getGrammarData={getDataFunc(target)}
            acceptSuggestion={(id, word) => this.acceptGrammar(id, word)}
            ignoreSuggestion={(tag) => this.ignoreGrammar(target, tag)}
            removeSelf={() => {
              me.removeElement(target.className);
            }}
          />
        );
        me.elementRefs[target.className] = grammarTooltip;
        me.addElement(grammarTooltip);
        openTimeout = undefined;
        openTarget = undefined;
      }, 400);
    };

    const cancelOpenTimeout = () => {
      clearTimeout(openTimeout);
      openTimeout = undefined;
      openTarget = undefined;
    };

    // @ts-expect-error
    this.cm.getGrammar = async() => await getGrammarData(this.cm.getValue(), this);

    this.ref.addEventListener(
      "mouseover",
      (evt) => {
        const targetClasses = evt.target.classList;
        const parentClasses = evt.target.parentNode.classList;
        if (parentClasses.contains("grammar-choice") || targetClasses.contains("grammar-choice")) {
          cancelCloseTimeout();
          return;
        }
        if (!targetClasses.contains("cm-grammar")) {
          cancelOpenTimeout();
          setCloseTimeout();
          return;
        }
        cancelCloseTimeout();
        if (me.elementRefs[evt.target.className]) {
          return;
        }
        setOpenTimeout(evt.target);
      },
      true
    );

    this.ref.addEventListener("click", (evt) => {
      const targetClasses = evt.target.classList;
      if (targetClasses.contains("cm-grammar")) {
        me.removeAllElements();
        clearTimeout(openTimeout);
        openTimeout = undefined;
        openTarget = undefined;
      }
    });
  }

  acceptGrammar(id, word) {
    if (word === "(omit)") word = "";
    // @ts-expect-error
    const mark = this.cm.getAllMarks().find((mark) => {
      return mark.className.split(" ").includes(`cm-grammar-id${id}`);
    });
    if (!mark) {
      console.error("Could not find grammar mark with id ", id);
      return;
    }
    const range = mark.find();
    // @ts-expect-error
    this.cm.replaceRange(word, range.from, range.to);
    this.removeAllElements();
    CodeMirror.signal(this.cm, "inputRead", this.cm);
  }

  ignoreGrammar(target, tag) {
    this.removeAllElements();
    CodeMirror.signal(this.cm, "grammar-ignore", [this.cm, tag]);
  }

  render() {
    const { options } = this.props;
    const { elements } = this.state;

    return (
      <>
        {elements.map((el) => el)}
        <ReactCodeMirrorWrapper
          {...this.props}
          options={{ ...options, ...CodeMirror.getUserSettings() }}
          ref={(cm) => this.setRef(cm)}
        />
      </>
    );
  }
}
