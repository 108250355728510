import { useQuery } from "@apollo/client";

export const useCorsisQuery = (query, variables, extraVars: any = {}) => {
  if (!extraVars) {
    extraVars = {};
  }
  if (!variables) {
    variables = {};
  }
  const noCachePolicies = new Set(["no-cache", "network-only"]);

  if (!noCachePolicies.has(extraVars.fetchPolicy)) {
    extraVars.nextFetchPolicy ||= "cache-first"; /* https://github.com/apollographql/apollo-client/issues/6760 */
  }
  extraVars.notifyOnNetworkStatusChange = true;
  let queryName = null;
  const operation = query.definitions.find((x) => x.kind == "OperationDefinition");
  if (operation?.name) {
    queryName = operation.name.value;
  }
  const start = new Date().getTime();
  const onCompleted = (): void => {
    if (!queryName) {
      return;
    }
    const time = (new Date().getTime() - start) / 1000;
    window.ga?.("send", "timing", "API query", queryName, time);
  };

  const res = useQuery(query, { variables, ...extraVars, onCompleted });
  const { refetch } = res;
  const _refetch = async(variables): Promise<any> => await refetch(variables).then(() => onCompleted());

  return { ...res, refetch: _refetch };
};
