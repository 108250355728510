import React from "react";
import { RatingField } from "../../common/components";
import { MarkdownWrapper } from "../../reports/common";
import { timeFrameValues } from "../../reports/KeyLists";
import { GQLReportKeyListItemTypeWithTrack } from "../../schemas/schema";

export class ReportKeyListTable extends React.Component<{
  title: string
  items: GQLReportKeyListItemTypeWithTrack[]
  withImpact?: boolean
  withTrack?: boolean
}> {
  protected renderImpactSimple(line: GQLReportKeyListItemTypeWithTrack) {
    return <td>{line.impact}</td>;
  }

  protected renderImpact(line: GQLReportKeyListItemTypeWithTrack) {
    if (!this.props.withImpact || line.impact != "High" || !(line.priority || line.timeframe || line.cost)) {
      return this.renderImpactSimple(line);
    }
    return (
      <td>
        <tr>
          <td>{line.impact}</td>
        </tr>
        <tr>
          <td>
            <div>
              <RatingField icon={"fire-icon"} initialRating={line.priority || 0} hideEmpty hideTooltips />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {!!line.timeframe && timeFrameValues[line.timeframe - 1]}
            {!line.timeframe && "Unknown"}
          </td>
        </tr>
        <tr>
          <td>
            <RatingField icon={"dollar-icon"} initialRating={line.cost || 0} hideEmpty />
          </td>
        </tr>
      </td>
    );
  }

  render() {
    const { title, items, withTrack } = this.props;
    return (
      <table className="report-detail-table report-key-sw-table">
        <tbody>
          <tr className="title-row">
            {withTrack && <th className={"track"}>Track</th>}
            <th className={"title"}>{title}</th>
            <th className={"benefits"}>Benefits</th>
            <th className={"impact"}>Impact</th>
          </tr>
          {items.map((item) => (
            <tr>
              {withTrack && <td>{item.report_track!.name}</td>}
              <td>{item.title}</td>
              <td>
                <MarkdownWrapper>{item.description}</MarkdownWrapper>
              </td>
              <td>
                <table className="impact">
                  <tbody>{this.renderImpact(item)}</tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
