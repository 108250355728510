import round from "lodash/round";

const roundSum = (value: number) => {
  if (value < 10) {
    return round(value, 1);
  }

  const length = `${round(value)}`.length;
  return round(value, 2 - length);
};

export const convertToCurrency = (amount, options) => {
  const { unit, thousands_indicator, millions_indicator, billions_indicator } = options;
  const value = Math.round(amount / 1000);

  let result = "";
  if (value < 1) {
    result = `0${thousands_indicator}`;
  } else if (value < 1000) {
    result = `${roundSum(value)}${thousands_indicator}`;
  } else if (value < 1000 * 1000) {
    result = `${roundSum(value / 1000)}${millions_indicator}`;
  } else {
    result = `${roundSum(value / 1000000)}${billions_indicator}`;
  }

  return `${unit}${result}`;
};

export const convertCurrencyRange = (range, options) => {
  const [low = 0, high = 0] = range;

  let str = convertToCurrency(low, options);
  const highStr = convertToCurrency(high, options);
  if (str !== highStr) {
    str += ` - ${highStr}`;
  }

  return str;
};

export const convertCurrencyRangeByValue = (value, options) => {
  const { ranges, ...otherOptions } = options;

  if (!ranges[value]) {
    return "";
  }

  let str = convertCurrencyRange(ranges[value], otherOptions);
  if (value === ranges.length - 1) {
    str += "+";
  }

  return str;
};

export const includesAllWords = (txt, query) => {
  const words = (query || "")
    .split(" ")
    .map((w) => w.trim().toLowerCase())
    .filter((w) => w.length > 0);

  if (words.length === 0) {
    return true;
  }

  return words.reduce((res, word) => res && txt.toLowerCase().includes(word), true);
};

export function createUUID() {
  let dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function ordinalRank(n: number | null, endString: string | null = null): string {
  if (n === null) {
    return "-";
  }
  if (n === 0) {
    return "*";
  }

  const rank = n > 1 ? Math.floor(n) : 1;
  const postfix = ["th", "st", "nd", "rd"];
  const v = rank % 100;
  const out = rank.toString() + (postfix[(v - 20) % 10] || postfix[v] || postfix[0]);
  if (v == 0 || !endString) {
    return out;
  }
  return [out, endString].join(" ");
}

const defaultPluralizer = (x: string) => {
  if (x.endsWith("y")) {
    return x.substr(0, x.length - 1) + "ies";
  }
  return x + "s";
};

export function pluralize(word: string, isPlural: any, pluralizer = defaultPluralizer) {
  if (typeof isPlural === "number") {
    isPlural = isPlural != 1;
  }
  if (isPlural) {
    return pluralizer(word);
  }
  return word;
}

export function urlize(val) {
  return val.replace(/ /g, "-").toLowerCase();
}

export function copyToClipboard(text: string) {
  function selectElementText(element) {
    // @ts-expect-error
    if (document.selection) {
      // @ts-expect-error
      const range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(element);
      window.getSelection()!.removeAllRanges();
      window.getSelection()!.addRange(range);
    }
  }
  const element = document.createElement("DIV");
  element.textContent = text;
  document.body.appendChild(element);
  selectElementText(element);
  document.execCommand("copy");
  element.remove();
}
