import gql from "graphql-tag";
import React from "react";
import { GQLCurrentUserPayload } from "../../../schemas/schema";
import { gqlQuerySilent } from "../../lib";
import { Prompt2FA } from "./Prompt2FA";
import { CurrentUserQuery } from "gql/current_user.graphql";

export class PromptControlBase<P> extends React.Component<P> {
  protected current_user: GQLCurrentUserPayload;
  userQuery = CurrentUserQuery;
  skipPrompt = gql`
    mutation dismissPrompt($action: String!, $prompt_name: String!) {
      dismiss_prompt(action: $action, prompt_name: $prompt_name) {
        id
        show_prompts {
          id
        }
      }
    }
  `;

  protected showPrompts() {
    const prompt = this.current_user.show_prompts!.map((x) => x.id)[0];
    let text;
    switch (prompt) {
      case "p_2fa":
        text = `Two-factor authentication (2FA) adds an extra layer of protection to your account
                by requiring you to enter a verification code, in addition to your password, every 30 days or
                whenever you access the system from a new device or location. The verification code will be sent via SMS.
               `;
        return (
          <Prompt2FA title={"Enable Two-Factor Authentication (2FA)?"} text={text} skipMutation={this.skipPrompt} />
        );
      case "m_2fa":
        text = ` Your company requires that all Corsis user accounts use 2FA for enhanced security.
                 2FA adds an extra layer of protection to your account by requiring you to enter a verification
                 code, in addition to your password, every 30 days or whenever you access the system from a new
                 device or location. The verification code will be sent via SMS.
                `;
        return (
          <Prompt2FA title={"Action Required: Secure Your Account with Two-Factor Authentication (2FA)"} text={text} />
        );
    }
    return null;
  }

  render() {
    return gqlQuerySilent(this.userQuery, {}, (res) => {
      this.current_user = res.current_user;
      return <>{res.current_user.show_prompts && this.showPrompts()}</>;
    });
  }
}
