import { FormikDropdown } from "formik-office-ui-fabric-react/dist";
import { PrimaryButton } from "office-ui-fabric-react";
import React from "react";

export class FormikDropdownCorsis extends React.Component<any> {
  private readonly control = React.createRef<any>();
  private showCloseButton = false;
  state = {
    showCloseButton: false
  };

  showOrHideButton() {
    if (this.showCloseButton != this.control.current.state.isOpen) {
      this.showCloseButton = this.control.current.state.isOpen;
      // this.setState({showCloseButton: this.control.current.state.isOpen});
      this.forceUpdate();
    }
  }

  onRenderList(props) {
    const _this = this.control.current;
    const { onRenderItem = _this._onRenderItem } = props;

    return (
      <>
        {props.options.map((item: any, index: number) => onRenderItem({ ...item, index }, _this._onRenderItem))}
        <PrimaryButton className={"dropdown-close-button"} onClick={() => _this._onDismiss()}>
          Close
        </PrimaryButton>
      </>
    );
  }

  render() {
    // @ts-expect-error
    window.control = this.control;
    return (
      <span>
        <FormikDropdown
          {...{ ref: this.control }}
          {...this.props}
          options={this.props.options}
          form={this.props.form}
          field={this.props.field}
          onDismiss={() => {
            this.showOrHideButton();
            this.props.modal && this.props.modal.current!.lockClose();
          }}
          componentRef={this.control}
          onRenderList={(props) => this.onRenderList(props)}
          // @ts-expect-error
          meta={undefined}
        />
      </span>
    );
  }
}
