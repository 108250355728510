import React from "react";
import { GQLComment } from "../../schemas/schema";
import BaseComponent from "../BaseComponent";

export class CommentManager {
  private static readonly ReactContext = React.createContext({});
  public static Provider = CommentManager.ReactContext.Provider;
  public static Consumer = CommentManager.ReactContext.Consumer;
  private readonly component: BaseComponent;
  comments: GQLComment[];

  constructor(component: any, comments: GQLComment[]) {
    this.component = component;
    this.comments = comments;
  }

  get has_unreviewed_comments(): boolean {
    return this.comments.filter((x) => !x.resolved).length > 0;
  }

  for_object(obj: any): GQLComment[] {
    let commentableId = obj.id;
    let typename = obj.__typename;

    if (obj.report_key_list_id) {
      // strengths / weaknesses
      commentableId = obj.report_key_list_id;
      typename = "ReportKeyList";
    }
    return this.comments.filter((x) => x.commentable_type == typename && x.commentable_id == commentableId);
  }

  for_objects(...objects: any[]): GQLComment[] {
    return ([] as GQLComment[]).concat(...objects.map((x) => this.for_object(x)));
  }
}
