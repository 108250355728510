import React from "react";
import { withContext, withTooltip } from "../../../common/lib";
import { CommentManager } from "../../contexts";
import PubSub from "pubsub-js";
import BaseComponent from "@/reports/BaseComponent";

interface CommentsTagProp {
  model: GQLObject
  onOpen?: (t: string) => void
  field?: string
  simple?: boolean
  withTooltip?: boolean
  prefix?: any
}

export class CommentsTag extends BaseComponent<CommentsTagProp, {}> {
  constructor(props: any) {
    super(props);

    this.unsub_tokens.push(
      PubSub.subscribe("update.Comment", () => {
        this.forceUpdate();
      })
    );
  }

  onClick = (type) => {
    const { onOpen } = this.props;

    return function() {
      if (!onOpen) {
        return;
      }

      onOpen(type);
    };
  };

  renderContent(open, resolved) {
    return (
      <>
        {open > 0 && (
          <a className="comments link" onClick={this.onClick("open")}>
            {open} Open
          </a>
        )}
        {open > 0 && resolved > 0 && ","}
        {resolved > 0 && (
          <a className="comments link ml-8" onClick={this.onClick("resolved")}>
            {resolved} Resolved
          </a>
        )}
      </>
    );
  }

  renderSimpleContent(open, resolved) {
    return (
      <>
        {(open > 0 || resolved > 0) && (
          <a className="mr-8 cursor-pointer text-blue" onClick={this.onClick("open")}>
            {open}
          </a>
        )}
        {resolved > 0 && (
          <>
            ({" "}
            <a className="cursor-pointer text-blue" onClick={this.onClick("resolved")}>
              {resolved}
            </a>{" "}
            )
          </>
        )}
      </>
    );
  }

  renderSimpleContentWithTooltips(open, resolved) {
    return (
      <>
        {(open > 0 || resolved > 0) &&
          withTooltip(
            "Review Open Comments",
            <a
              className="mr-8 cursor-pointer text-blue"
              onClick={this.onClick("open")}
              data-testid="review-open-comments"
            >
              {open}
            </a>
          )}
        {resolved > 0 && (
          <>
            {"( "}
            {withTooltip(
              "Review Resolved Comments",
              <a
                className="cursor-pointer text-blue"
                onClick={this.onClick("resolved")}
                data-testid="review-resolved-comments"
              >
                {resolved}
              </a>
            )}
            {" )"}
          </>
        )}
      </>
    );
  }

  render() {
    const { model, simple, withTooltip, prefix } = this.props;

    return withContext(CommentManager, (manager) => {
      let comments = manager.for_object(model);
      if (this.props.field) {
        comments = comments.filter((x) => x.field == this.props.field);
      }
      const resolved = comments.filter((x) => x.resolved).length;
      const open = comments.length - resolved;

      if (open === 0 && resolved === 0) {
        return null;
      }

      let content = null as any;
      if (simple && !withTooltip) {
        content = this.renderSimpleContent(open, resolved);
      } else if (simple && withTooltip) {
        content = this.renderSimpleContentWithTooltips(open, resolved);
      } else {
        content = this.renderContent(open, resolved);
      }

      return (
        <>
          {prefix}
          {content}
        </>
      );
    });
  }
}
