import { DocumentNode } from "graphql";
import { ChoiceGroup, DefaultButton, Dropdown, PrimaryButton } from "office-ui-fabric-react";
import React from "react";
import { CreateKeyListItemMutation, UpdateKeyListItemMutation } from "gql/mutations/key_list_item.graphql";
import { Modal, RatingField } from "../../common/components";
import { gqlMutation, gqlQuery } from "../../common/lib";
import { GQLReportKeyListItem } from "../../schemas/schema";
import BaseComponent from "../BaseComponent";
import { stringToIChoice, TextareaAutoSizeWrapper } from "../common";
import { timeFrameValues } from "./constants";

const timeFrameOptions = timeFrameValues.map((x, i) => ({ key: i + 1, text: x }));
timeFrameOptions.splice(0, 0, { key: 0, text: "Select one" });

interface Translations {
  object_type_verbose: string
  object_type_verbose_plural: string
}

interface KeyListEditModalProps extends Translations {
  item?: GQLReportKeyListItem
  onClose: () => void
  description_field_name?: string
  validate: Function | null
}

export class KeyListEditModal<P> extends BaseComponent<KeyListEditModalProps & P> {
  private tooltipCounters: any;
  protected buttonsClassName = "buttons";
  protected modalClassName = "key-lists-modal";
  protected innerModalClassName = "";
  protected dataTestId = "key-list-edit-modal";

  get fields() {
    return new Set(["title", "description", "impact"]);
  }

  state = {
    show: true,
    pristine: true,
    fieldData: {} as any
  } as any;

  protected query = null as DocumentNode | null;
  protected editMutation = UpdateKeyListItemMutation;
  protected createMutation = CreateKeyListItemMutation;

  constructor(props: any) {
    super(props);
    this.initialize(props);
  }

  protected initialize(props) {
    const { fieldData } = this.state;
    this.fields.forEach((field) => {
      fieldData[field] = props.item ? props.item[field] : "";
    });
    fieldData.timeframe = fieldData.timeframe || 3;
    this.state.fieldData = fieldData;
  }

  isPristine() {
    return this.state.pristine;
  }

  get title() {
    let title = `KEY ${this.props.object_type_verbose_plural.toUpperCase()}`;
    if (this.props.item && parseInt(this.props.item.id!) > 0) {
      title += ` - ID: ${this.props.item.id}`;
    }
    return title;
  }

  protected renderFields(_) {
    const linkField = (fieldName: string) => ({
      value: this.state.fieldData[fieldName],
      onChange: this.alterField(fieldName).bind(this)
    });
    const impactIsHigh = this.state.fieldData.impact == "High";

    return (
      <>
        <label>Impact</label>
        <ChoiceGroup
          defaultSelectedKey={this.props.item ? this.props.item.impact : ""}
          {...linkField("impact")}
          onChange={(e, val) => this.setFieldData({ impact: val.key })}
          options={["Low", "Medium", "High"].map(stringToIChoice)}
        />
        <div className="field">
          <label>Summary</label>
          <TextareaAutoSizeWrapper
            id="formControlsBenefit"
            autoFocus
            rows={2}
            className={"textarea-block"}
            label={this.props.object_type_verbose}
            {...linkField("title")}
            data-testid="field-title"
          />
        </div>
        <div className="field">
          <label>{this.props.description_field_name}</label>
          <TextareaAutoSizeWrapper
            id="formControlsBenefit"
            rows={2}
            className={"textarea-block"}
            label={this.props.object_type_verbose}
            {...linkField("description")}
            data-testid="field-description"
          />
        </div>
        <div className={"rating-group"}>
          {this.fields.has("priority") && impactIsHigh && (
            <div className={"priorityRating"}>
              <label>Priority</label>
              <RatingField
                icon={"fire-icon"}
                initialRating={this.state.fieldData.priority}
                onChange={(priority) => this.setFieldData({ priority })}
              />
            </div>
          )}
          {this.fields.has("cost") && impactIsHigh && (
            <div className={"costEstimates"}>
              <label>Cost Estimate</label>
              <RatingField
                icon={"dollar-icon"}
                initialRating={this.state.fieldData.cost}
                onChange={(cost) => this.setFieldData({ cost })}
              />
            </div>
          )}
          {this.fields.has("timeframe") && impactIsHigh && (
            <div className={"timeFrames"}>
              <label>Timeframe Estimate</label>
              <Dropdown
                onChange={(e, val) => this.setFieldData({ timeframe: val.key })}
                options={timeFrameOptions}
                selectedKey={this.state.fieldData.timeframe}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  protected renderButtons() {
    const mutation = !this.editMode ? this.createMutation : this.editMutation;

    return gqlMutation(mutation, (mutateFunc) => (
      <div className={this.buttonsClassName}>
        <PrimaryButton
          disabled={this.props.validate ? !this.props.validate(this.state.fieldData) : !this.validate()}
          onClick={() => this.save(mutateFunc)}
          data-testid="save-button"
        >
          Save
        </PrimaryButton>
        <DefaultButton className="ml-16" onClick={() => this.props.onClose()} data-testid="cancel-button">
          Cancel
        </DefaultButton>
      </div>
    ));
  }

  protected renderModal = (queryResult = {}) => {
    return (
      <Modal
        className={this.modalClassName}
        title={this.title}
        open={!!this.props.item}
        onClose={this.handleClose.bind(this)}
        innerFormPristine={this.isPristine.bind(this)}
        styles={{ container: this.innerModalClassName }}
        data-testid={this.dataTestId}
      >
        {this.renderFields(queryResult)}
        {this.renderButtons()}
      </Modal>
    );
  };

  protected getQueryVariables(): any {
    return {};
  }

  render() {
    this.tooltipCounters = {};
    const variables = this.getQueryVariables();

    return !this.query ? this.renderModal() : gqlQuery(this.query, variables, this.renderModal);
  }

  protected validate(): boolean {
    return true;
  }

  protected get editMode() {
    return this.props.item?.id && this.props.item.id != "0";
  }

  protected setFieldData(hash: any) {
    const { fieldData } = this.state;
    this.setState({ fieldData: { ...fieldData, ...hash }, pristine: false });
  }

  alterField(fieldName: string): any {
    const me = this;
    return function(e: Event) {
      const { value } = e.currentTarget as HTMLTextAreaElement;
      const { fieldData } = me.state;
      me.setState({ fieldData: { ...fieldData, [fieldName]: value }, pristine: false });
    };
  }

  protected save(mutateFunc): void {
    const variables = this.getMutationVariables();
    mutateFunc({ variables }).then(this.handleClose);
  }

  protected getMutationVariables(): any {
    const { item } = this.props;

    const input = {
      report_key_list_id: item.report_key_list_id,
      ...this.state.fieldData,
      ...(this.editMode && { gid: item.gid })
    };

    return { input };
  }

  protected handleClose = () => this.props.onClose();
}
