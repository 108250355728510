import cn from "classnames";
import { TextField } from "office-ui-fabric-react";
import React from "react";
import { getMaxLengthMesssage, getRequiredMessage } from "../../utils/validators";
import { CodeMirrorInput } from "./CodeMirrorInput";

interface LimitedTextFieldProps {
  className?: string
  label?: string
  multiline?: boolean
  required?: boolean
  noBreak?: boolean
  disabled?: boolean
  checkGrammar?: boolean
  characterLimit: number
  value: string
  placeholder?: string
  onChange: (e, v) => any
  errorMessages?: {
    required: string
    limitExceeded: string
  }
  dataTestId?: string
}

export class LimitedTextField extends React.Component<LimitedTextFieldProps> {
  removeBreaklinesRegex = /(\r\n|\n|\r)/gm;

  static defaultProps = {
    characterLimit: 250,
    errorMessages: {}
  };

  get value(): string {
    return this.props.value || "";
  }

  get errorMessages() {
    const { label, characterLimit, errorMessages } = this.props;
    return {
      required: getRequiredMessage(label),
      limitExceeded: getMaxLengthMesssage(label, characterLimit),
      ...errorMessages
    };
  }

  getErrorMessage(): string {
    let errorName = "";
    const { required, characterLimit } = this.props;
    const length = this.value.length;
    if (required && length == 0) {
      errorName = "required";
    } else if (length > characterLimit) {
      errorName = "limitExceeded";
    }
    return this.errorMessages[errorName] || "";
  }

  limitExceeded(): boolean {
    return this.value.length > this.props.characterLimit;
  }

  onChange(e, v): void {
    this.props.onChange(e, v.replace(this.removeBreaklinesRegex, ""));
  }

  onKeyPress(e): void {
    if (this.props.noBreak && e.key === "Enter") {
      e.preventDefault();
    }
  }

  handleOnKeyDownCodeMirror = (editor, event) => {
    if (this.props.noBreak && event.key === "Enter") {
      event.preventDefault();
    }
  };

  handleChangeCodeMirror = (v) => {
    this.props.onChange({}, v.replace(this.removeBreaklinesRegex, ""));
  };

  render() {
    const { label, multiline, required, dataTestId, disabled, checkGrammar, characterLimit, className, placeholder } =
      this.props;
    const characterCount = this.value.length;
    const errorMessage = this.getErrorMessage();
    const limitExceeded = this.limitExceeded();
    const hasError = limitExceeded || errorMessage;

    return (
      <div className="flex flex-col">
        <div className="flex-container flex flex-col">
          {!checkGrammar && (
            <TextField
              label={label}
              styles={{
                fieldGroup: "border-0",
                field: cn(className, "border", { "border-darkred": hasError }),
                errorMessage: "absolute m-0 py-4"
              }}
              multiline={multiline}
              required={required}
              value={this.value}
              onChange={(e, v) => this.onChange(e, v)}
              onKeyPress={(e) => this.onKeyPress(e)}
              data-testid={dataTestId}
              errorMessage={errorMessage}
              disabled={disabled}
              placeholder={placeholder}
            />
          )}
          {checkGrammar && (
            <div className="flex flex-col" data-testid={dataTestId}>
              <CodeMirrorInput
                className={cn(className, "height-60 border", { "border-darkred": hasError })}
                value={this.value}
                onChange={this.handleChangeCodeMirror}
                onKeyDown={this.handleOnKeyDownCodeMirror}
                placeholder={placeholder}
              />
            </div>
          )}
          <div
            className={cn("character-count flex justify-between items-center my-4", {
              "not-valid text-darkred": limitExceeded,
              valid: !limitExceeded
            })}
          >
            {hasError && checkGrammar && <span data-testid="error-message">
              {errorMessage}
            </span>}
            <span data-testid="char-counter">{`${characterCount}/${characterLimit}`}</span>
          </div>
        </div>
      </div>
    );
  }
}
