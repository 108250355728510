import ActionCable from "actioncable";
import React from "react";
import { PacmanLoader } from "react-spinners";
import cn from "classnames";
import { Modal } from "../../common/components";

const empty = () => {};

export interface ProgressModalProps {
  objectId: string
  modalTitle: string
  dataTestId: string
  initMessage: string
  channelName: string
  onClose?: () => void
  onReceive?: (msg: any) => void
  onComplete?: (msg: any) => void
}

export default class ProgressModal<P extends ProgressModalProps> extends React.Component<P> {
  protected sub: ActionCable.Channel;
  protected cable: ActionCable.Cable;
  protected className = "report-download";

  state = {
    status: "Starting up",
    jobId: 0,
    meta: {}
  };

  initConnection(connectionData) {
    this.cable = ActionCable.createConsumer("/cable");
    this.sub = this.cable.subscriptions.create(connectionData, {
      connected: () => this.sub.send({ action: "start" }),
      disconnected: empty,
      received: (msg) => this.onReceive(msg)
    });
  }

  public setStatus(status: string) {
    this.setState({ status });
  }

  componentDidMount() {
    const { channelName, objectId } = this.props;
    this.initConnection({ channel: channelName, objectId });
  }

  renderFooter() {
    return <></>;
  }

  statusTitle(): JSX.Element | null {
    return <>{this.props.initMessage}</>;
  }

  render() {
    const { status } = this.state;
    const { onClose, modalTitle, dataTestId } = this.props;
    return (
      <Modal open onClose={onClose} title={modalTitle}>
        <div className={cn("download-app", this.className)} data-testid={dataTestId}>
          <h1 className="status-title">{this.statusTitle()}</h1>
          <div className="pacman" >
            <PacmanLoader />
          </div>
          <div className={"status"}>
            {status.toUpperCase()}
          </div>
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }

  protected onReceive(msg: any) {
    if (msg.status == "complete") {
      this.sub.unsubscribe();
      this.cable.disconnect();
      if (this.props.onComplete) {
        this.props.onComplete(msg);
        return;
      }
    }
    if (msg.status) {
      this.setStatus(msg.status);
    }
    if (this.props.onReceive) {
      this.props.onReceive(msg);
    }
  }
}
