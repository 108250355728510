import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { firstBy } from "thenby";
import { copyParameters } from "../../../admin/utils";
import { TrackParameters } from "../interfaces";
import { SortableContainerEl } from "./SortableContainerEl";
import { SortableItem } from "./SortableItem";

export interface IObjectSelectorBaseProps {
  form?: any
  field?: any
  objects: any[]
  onChange: (o: any[]) => void
}

export class ObjectSelectorBase<Props extends IObjectSelectorBaseProps> extends React.Component<Props> {
  state = {
    objects: [] as any[]
  };

  private initialized = false;
  protected object_name = "object";
  protected parameters = [] as string[];
  protected id: string;
  protected listClassName = "";
  protected testid = "track-selector-container";

  emptyLine() {
    return {};
  }

  addObject() {
    const { objects } = this.state;
    objects.push(this.emptyLine());
    this.setState({ objects });
    this.setTouched();
  }

  setTouched() {
    this.initialized && this.props.field && this.props.form?.setFieldTouched(this.props.field.name);
  }

  componentWillMount(): void {
    const { objects } = this.props;
    this.state.objects = objects;
    if (objects.length == 0) {
      this.addObject();
    }
    this.initialized = true;
    this.id = "track-selector" + (Math.random() + "").substr(2);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { objects } = this.state;
    const t = objects[oldIndex];
    objects.splice(oldIndex, 1);
    objects.splice(newIndex, 0, t);
    for (let i = 0; i < objects.length; i++) {
      objects[i].sort_order = i;
    }
    this.props.onChange(objects);
  };

  createHelperContainer = () => {
    return document.querySelectorAll(`#${this.id} .track-selector`)[0] as HTMLElement;
  };

  protected renderAddButton() {
    return (
      <div className={"pointer add-button"} onClick={() => this.addObject()} data-testid="add-next-object">
        <FontAwesomeIcon icon={["fal", "plus-square"]} size={"2x"} />
        <label>Add next {this.object_name}</label>
      </div>
    );
  }

  renderHeaders(): JSX.Element | null {
    return null;
  }

  renderLine(def, i): JSX.Element | null {
    return null;
  }

  showError() {
    if (this.props.form?.errors[this.props.field?.name] && this.props.form.touched[this.props.field?.name]) {
      const error = this.props.form.errors[this.props.field?.name];
      if (error != `${this.props.field?.name} is invalid`) {
        return (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {this.props.form.errors[this.props.field?.name]}
          </MessageBar>
        );
      }
    }
    return null;
  }

  protected get showAddButton() {
    // show Add new button when there's no object or first argument of last object was inputed
    const objects = this.items;
    return objects.length == 0 || objects[objects.length - 1][this.parameters[0]];
  }

  protected get items() {
    return this.state.objects;
  }

  render() {
    let objects = this.items;
    objects = objects.sort(firstBy((x) => x.sort_order));

    return (
      <span id={this.id} className={"track-selector-container"} data-testid={this.testid}>
        {this.showError()}
        <SortableContainerEl
          className={this.listClassName}
          onSortEnd={this.onSortEnd}
          headers={this.renderHeaders()}
          useDragHandle
          helperClass={"on-drag"}
          helperContainer={this.createHelperContainer}
        >
          {objects.map((def, i) => (
            <SortableItem key={`item-${i}`} index={i} value={this.renderLine(def, i)} />
          ))}
        </SortableContainerEl>
        {this.showAddButton && this.renderAddButton()}
      </span>
    );
  }

  onUpdate(objects) {
    this.setState({ objects });
    this.setTouched();
    this.props.onChange(objects);
  }

  copyParameters(opts, object, allowNull = false) {
    copyParameters(opts, object, this.parameters, allowNull);
  }

  delete(opts, index) {
    const { objects } = this.state;
    objects.splice(index, 1);
    this.onUpdate(objects);
  }

  protected alterObject(index) {
    const me = this;
    return function(opts: TrackParameters) {
      const { objects } = me.state;
      const obj = objects[index];

      if (opts.delete) {
        me.delete(opts, index);
      } else {
        me.copyParameters(opts, obj);
      }
      me.setState({ objects });
      me.setTouched();
      me.onUpdate(objects);
    };
  }
}
