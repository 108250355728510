import { deep_clone, fuzzySearcher } from "@/common/utils";
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  GroupHeader,
  IColumn,
  IDetailsRowProps,
  IGroup,
  IGroupDividerProps,
  IRenderFunction,
  ScrollablePane,
  SearchBox,
  Selection,
  SelectionMode
} from "office-ui-fabric-react";
import React from "react";
import { firstBy } from "thenby";
import { friendlyDate, make_groups_from_items } from "../admin/utils";

interface Props {
  columns: any[]
  items: any[]
  search_fields?: string[]
  onSelect?: (selection: ID[]) => void
  selectedInitial?: ID[]
  noVirtualize?: boolean
  scrollable?: boolean
  sortingAlg?: (obj) => any
  detailsListProps?: any
  queryFilters?: any
}

export default class SortableTable extends React.Component<Props> {
  private readonly preserveGroupExpansion = true;
  // TODO preserveGroupExpansion = false currently broken. Function should be renamed to store groupExpansion in local storage and current behaviour should be default.
  private groupsInitialized = false;
  private _itemCount = 0;
  public set itemCount(value: number) {
    if (value != this._itemCount) {
      this._itemCount = value;
      PubSub.publish("list.itemCount", value);
    }
  }

  public get itemCount() {
    return this._itemCount;
  }

  private readonly _selection: Selection;
  static renderDate = (field_name) => {
    return (item: any) => {
      const o = friendlyDate(item[field_name]);
      if (o == "Invalid date") {
        return "";
      }
      return o;
    };
  };

  state = {
    columns: [] as IColumn[],
    currColumn: null as any,
    search: "",
    query: {} as any,
    groupsExpanded: false
  };

  groups = undefined as IGroup[] | undefined;
  private readonly searchBox = React.createRef<any>();
  get search_fields() {
    return this.props.search_fields || [];
  }

  get columns() {
    return this.props.columns;
  }

  get _sortingAlg() {
    const def = (item) => (item?.toLowerCase ? item.toLowerCase() : item);
    return this.props.sortingAlg || def;
  }

  constructor(props) {
    super(props);
    const initSortColumn = this.props.columns.find(({ sortedOnInit = false }) => sortedOnInit);
    const firstColumn = this.props.columns[0];
    this.state = {
      columns: this.props.columns.map((x) => this.column(x)),
      currColumn: initSortColumn || firstColumn,
      search: "",
      query: {},
      groupsExpanded: false
    };
  }

  protected groupExpandStateChanged(group: IGroup) {
    if (this.preserveGroupExpansion) {
      const value = this.state.groupsExpanded || {};
      value[group.key] = !group.isCollapsed;
      this.setState({ groupsExpanded: value });
    }
  }

  protected onToggleCollapseAll(isAllCollapsed: boolean) {
    if (this.preserveGroupExpansion && this.groups) {
      const value = this.state.groupsExpanded || {};
      this.groups.forEach((group) => {
        value[group.key] = isAllCollapsed;
      });
      this.setState({ groupsExpanded: value });
    }
  }

  private column(col) {
    col.onColumnClick = this._onColumnClick;
    col.key = col.key || col.name;
    col.fieldName = col.key;
    return col;
  }

  protected addToItem(allItems) {
    return (item) => item;
  }

  protected search_field_options() {
    return { threshold: 0.08, tokenize: true, matchAllTokens: true, ignoreLocation: true };
  }

  data_pre_sorted = false;
  protected _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    if (!items) {
      return [];
    }

    this.columns.forEach((col) => {
      if (col.allowChars) {
        col.minWidth = window.emSize() * col.allowChars;
        col.maxWidth = col.minWidth + 20;
      }
    });
    items = deep_clone(items).map(this.addToItem(items));
    const columnGet = (k) => this.columns.find((x) => x.key == k);
    const columnRender = (column, item) =>
      column.onRender ? column.onRender(item, items) : (item[column.key] as string);
    const columnRenderShow = (column, item) => {
      const out = item[column.key];
      if (column.isNew) {
        return (
          <>
            {item.is_new ? <span className="is-new">new</span> : null}
            {out}
          </>
        );
      }
      if (column.isDate) {
        return SortableTable.renderDate(column.key)(item);
      }
      return out;
    };
    const columnRenderSearch = (column, item) => (column.onSearch ? column.onSearch(item) : columnRender(column, item));

    const getSearchFields = (item) => {
      const out = {} as any;
      out.gid = item.gid;
      for (const field of this.search_fields) {
        out[field] = columnRenderSearch(columnGet(field), item);
      }
      return out;
    };
    const sortColumn = columnGet(key);

    if (this.state.search) {
      const searcher = fuzzySearcher(items.map(getSearchFields), this.search_fields, this.search_field_options());
      const resultArr = searcher.search(this.state.search).map((x) => x.item.gid);
      const result = new Set<string>(resultArr);
      // @ts-expect-error
      items = items.filter((x) => result.has(x.gid));
    }

    const columnRenderSort = (column, item) => {
      if (column.key.endsWith("_at")) {
        return new Date(item[column.key]).getTime();
      }
      return columnRender(sortColumn, item);
    };

    const sortingAlg = firstBy(
      (item) => this._sortingAlg(columnRenderSort(sortColumn, item)),
      isSortedDescending ? -1 : 1
    );

    if (!this.data_pre_sorted) {
      items = items.slice(0).sort(sortingAlg);
    }

    const makesGroupColumns = this.columns.filter((x) => x.makesGroup);
    if (makesGroupColumns.length) {
      let i = 0;
      let sortingAlg;
      let thisSort;
      for (const column of makesGroupColumns) {
        thisSort = (item) => this._sortingAlg(columnRender(column, item));
        if (!this.data_pre_sorted) {
          if (i == 0) {
            sortingAlg = firstBy(thisSort);
          } else {
            sortingAlg = sortingAlg.thenBy(thisSort);
          }
        }
        i++;
      }
      if (!this.data_pre_sorted) {
        items = items.sort(sortingAlg);
      }
    }

    for (let i = 0; i < items.length; i++) {
      for (const column of this.columns.filter((x) => !x.key.startsWith("__"))) {
        items[i][column.key + "--orig"] = items[i][column.key];
        items[i][column.key + "--group"] = columnRender(column, items[i]);
        items[i][column.key] = columnRenderShow(column, items[i]);
      }
    }

    if (makesGroupColumns.length) {
      let groups = [] as IGroup[];
      let n = 0;
      while (true) {
        const column = makesGroupColumns[n];
        if (!column) {
          break;
        }
        const assignLevel = (group) => {
          group.level = n + 1;
          return group;
        };
        if (n == 0) {
          groups = make_groups_from_items(items, (item) => item[column.key + "--group"]).map(assignLevel);
        } else {
          for (const group of groups) {
            group.children = make_groups_from_items(
              items.slice(group.startIndex, group.startIndex + group.count),
              (item) => item[column.key + "--group"],
              group.startIndex
            ).map(assignLevel);
          }
        }
        column.className = "hide";
        column.maxWidth = column.maxWidth || 150;
        n++;
      }

      this.groups = groups;
    }

    if (this.groups && this.groups.length > 0) {
      if (this.preserveGroupExpansion) {
        const groups_expanded = this.state.groupsExpanded || {};
        this.groups.map((x) => {
          x.isCollapsed = groups_expanded[x.key] == undefined ? true : groups_expanded[x.key];
        });
      } else {
        if (!this.groupsInitialized) {
          this.groups.map((x) => {
            x.isCollapsed = true;
          });
          this.groupsInitialized = true;
        }
      }
    }
    return items;
  }

  protected _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    this.setState({
      columns: newColumns,
      currColumn
    });
  };

  extraDetailsProps() {
    return {};
  }

  onPreRender() {}
  onRenderRow = (props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
    return (
      <div data-testid="detail-list-row" data-selection-toggle="true">
        {defaultRender && defaultRender(props)}
      </div>
    );
  };

  public filteredItems() {
    const { currColumn } = this.state;
    const items = deep_clone(this.props.items);
    const filteredItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    return filteredItems;
  }

  render() {
    this.onPreRender();
    const filteredItems = this.filteredItems();
    this.itemCount = filteredItems.length;
    let extraDetailsProps = this.extraDetailsProps();
    if (this.props.noVirtualize) {
      extraDetailsProps.onShouldVirtualize = () => false;
    }
    let ListContainer = React.Fragment;
    if (this.props.scrollable) {
      // @ts-expect-error
      ListContainer = ScrollablePane;
      extraDetailsProps.layoutMode = DetailsListLayoutMode.justified;
      extraDetailsProps.constrainMode = ConstrainMode.horizontalConstrained;
    }
    if (this.props.detailsListProps) {
      extraDetailsProps = { ...extraDetailsProps, ...this.props.detailsListProps };
    }
    return (
      <>
        {!!this.search_fields.length && (
          <SearchBox
            data-testid="report-search-box"
            placeholder="Search"
            componentRef={this.searchBox}
            onSearch={(search) => {
              console.log(search);
              this.setState({ search });
            }}
            onFocus={() => console.log("onFocus called")}
            onBlur={() => console.log("onBlur called")}
            onChange={() => console.log("onChange called")}
          />
        )}
        <ListContainer>
          <DetailsList
            compact
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderRow={this.onRenderRow}
            setKey={filteredItems.map((x: any) => x.id).join(" ")}
            items={filteredItems}
            groups={this.groups}
            groupProps={{
              isAllGroupsCollapsed: true,
              onRenderHeader: this._onRenderHeader,
              onToggleCollapseAll: this.onToggleCollapseAll.bind(this),
              headerProps: { onToggleCollapse: this.groupExpandStateChanged.bind(this) }
            }}
            // isHeaderVisible={true} columns={this.columns.filter(x => !x.makesGroup)}>
            isHeaderVisible={true}
            columns={this.columns}
            {...extraDetailsProps}
          />
        </ListContainer>
      </>
    );
  }

  protected _onRenderHeader(props: IGroupDividerProps): JSX.Element {
    const onToggleSelectGroup = () => {
      props.onToggleCollapse!(props.group!);
    };
    return <GroupHeader {...props} onToggleSelectGroup={onToggleSelectGroup} />;
  }
}
