import { IAssignmentDefinition } from "../../../common/components/IndicatorAssignments/interfaces";

export class Values {
  private readonly acceptedFields: string[];
  private readonly assignments: IAssignmentDefinition[];

  constructor(acceptedFields = [] as string[], initialValues = {} as any) {
    this.acceptedFields = acceptedFields;

    for (const field of this.acceptedFields) {
      if (field.endsWith("_ids")) {
        this[field] = [];
      }
      if (initialValues[field] != undefined) {
        this[field] = initialValues[field];
      }
    }
  }

  feed(data) {
    for (const key in data) {
      this[key] = data[key];
    }
  }

  clean() {
    const out = {};
    for (const field of this.acceptedFields) {
      out[field] = this[field];
      // makes Option values easier
      if (field.endsWith("_id")) {
        out[field] = parseInt(this[field]);
      }
      if (field.endsWith("_ids")) {
        out[field] = this[field].map(x => parseInt(x));
      }
    }

    return out;
  }

  getAssignments() {
    return this.assignments;
  }
}
