import React from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import PubSub from "pubsub-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextualMenu, DefaultButton, IDragOptions, Modal as FabModal } from "office-ui-fabric-react";
import styles from "../../admin/modal.module.scss";

export class Modal extends React.Component<ModalProps> implements IModal {
  private readonly _dragOptions: IDragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
    dragHandleSelector: "div[data-target=modal-header]",
    keepInBounds: true
  };

  public static defaultProps = {
    checkChanges: true,
    open: true,
    buttons: (m) => null
  };

  private readonly modalRef = React.createRef<HTMLDivElement>();
  private closeLocked = false;
  private sub: any;

  componentDidMount(): void {
    this.sub = PubSub.subscribe("global.escape", this.onClose);
  }

  componentWillUnmount(): void {
    PubSub.unsubscribe(this.sub);
  }

  createCancelButton() {
    return (
      <DefaultButton onClick={this.onClose} className={styles.cancelButton}>
        Cancel
      </DefaultButton>
    );
  }

  lockClose = (seconds?: number) => {
    seconds = seconds || 2;
    this.closeLocked = true;
    window.setTimeout(() => {
      this.closeLocked = false;
    }, seconds * 1000);
  };

  formSubmitEnabled = () => {
    const node = ReactDOM.findDOMNode(this.modalRef.current!) as HTMLElement;
    let btn = node.querySelector(".form-submit");
    if (!btn) {
      btn = node.querySelector(".buttons .ms-Button--primary");
    }

    if (btn && this.props.checkChanges) {
      // @ts-expect-error
      return !btn.disabled;
    } else {
      return false;
    }
  };

  modalCloseConfirmRequired = () => {
    if (!this.props.checkChanges) {
      return false;
    }

    const { innerFormPristine } = this.props;
    if (innerFormPristine) {
      return !innerFormPristine();
    } else {
      return this.formSubmitEnabled();
    }
  };

  onCloseWithChangesCheck = () => {
    if (this.closeLocked) {
      return;
    }

    const areYouSure = "You have unsaved changes. Are you sure that you want to close this modal?";

    if (this.modalCloseConfirmRequired()) {
      if (!confirm(areYouSure)) {
        return;
      }
    }

    this.onClose();
  };

  onClose = () => {
    if (this.closeLocked) {
      return;
    }

    this.props.onClose && this.props.onClose();
  };

  render() {
    const { bodyClassName, onClickOutside } = this.props;
    const buttons = this.props.buttons!(this);
    const containerClassName = this.props.styles ? this.props.styles.container : styles.container;
    const titleClassName = cn(styles.title, { "target-company-industry-modal-title": this.props.styles?.title });
    const headerClassName = cn(styles.header, this.props.styles?.header);

    return (
      <FabModal
        layerProps={{ eventBubblingEnabled: true }}
        titleAriaId={this.props.title}
        subtitleAriaId={this.props.title}
        isOpen={this.props.open}
        onDismiss={onClickOutside || this.onCloseWithChangesCheck}
        isBlocking={false}
        dragOptions={this._dragOptions}
        // containerClassName={[styles.container, this.props.className || ''].join(' ')}
        containerClassName={containerClassName}
        styles={this.props.styles}
        className={this.props.className}
      >
        <span className="Modal" data-testid={this.props["data-testid"]}>
          <div className={headerClassName} data-testid="modal-header" data-target="modal-header">
            <span className={titleClassName}>{this.props.title}</span>
            {this.props.onClose && (
              <span className={styles.closeButton} onClick={this.onClose} data-testid="btn-modal-close">
                <FontAwesomeIcon icon={["fal", "times"]} size={"2x"} inverse={false} />
              </span>
            )}
          </div>
          <div className={cn(styles.body, bodyClassName)} ref={this.modalRef} data-testid="modal-body">
            {this.props.children}
          </div>
          {buttons && <div className={cn(styles.footer, "modal-footer")}>{buttons}</div>}
        </span>
      </FabModal>
    );
  }
}
