import React from "react";
import { Modal } from "../../../common/components";
import { Report } from "../../../schemas/Report";
import { ReportCloneForm } from "./ReportCloneForm";

interface Props {
  report: Report
  onClose: () => void
}

export class ReportCloneModal extends React.Component<Props> {
  render() {
    return (
      <Modal open onClose={this.props.onClose} title="Report clone">
        <ReportCloneForm object={this.props.report} {...{ onClose: this.props.onClose }} />
      </Modal>
    );
  }
}
