import cn from "classnames";
import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { fuzzySearcher } from "../../common/utils";

interface IProps {
  className?: string
  options: SelectOption[]
  label?: string
  onChange: (options) => void
  value: ((fetchOption: (s: string) => SelectOption[]) => SelectOption[]) | SelectOption[]
  isSearchable?: boolean
  isMulti?: boolean
  showAllOptions?: boolean
  field?: any
  dataTestid?: string
  placeholder?: string
  labelKey?: string
}

export class SearchSelect extends React.Component<IProps> {
  private searcher;
  private readonly options;
  buildSearcher() {
    this.searcher = fuzzySearcher(this.props.options, ["value"]);
  }

  componentWillMount(): void {
    this.buildSearcher();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options != this.props.options) {
      this.buildSearcher();
    }
  }

  render() {
    let { className, options, onChange, value, label, showAllOptions, labelKey = "value" } = this.props;
    if (!value && this.props.field.value) {
      value = this.props.field.value;
      // @ts-expect-error
      value = options.find((x) => x.key == value);
      // TODO: this isn't covering isMulti
      showAllOptions = true;
    }
    if (typeof value == "function") {
      value = value((key) => this.props.options.filter((x) => x.key == key)!);
    }
    if (!onChange) {
      // compatibility with Formik
      // @ts-expect-error
      const { form, field } = this.props;
      onChange = (value) => form.setFieldValue(field.name, value.key);
    }
    let Component: any = Select;
    const extraAttrs = {};
    if (!showAllOptions) {
      Component = AsyncSelect;
      extraAttrs.loadOptions = this.filterOptions.bind(this);
    }
    return (
      <React.Fragment>
        {label && <label className="ms-Label ms-Dropdown-label formLabel">{label}</label>}
        <Component
          id={this.props.field ? this.props.field.name : ""}
          value={value}
          noOptionsMessage={(inputValue) => (inputValue ? "Start typing to get options" : "No options found")}
          options={options}
          getOptionLabel={(option) => option[labelKey]}
          getOptionValue={(option) => option.key!}
          className={cn("basic-multi-select", className)}
          classNamePrefix="select"
          onChange={(options) => onChange(options)}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999999 }) }}
          isSearchable
          isMulti={this.props.isMulti}
          data-testid={this.props.dataTestid}
          placeholder={this.props.placeholder}
          {...extraAttrs}
        />
      </React.Fragment>
    );
  }

  private filterOptions(inputValue, callback) {
    const opts = this.searcher.search(inputValue).map((x) => x.item);
    callback(opts);
  }
}
