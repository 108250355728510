import { gql } from "@apollo/client";
import { PrimaryButton, TextField } from "office-ui-fabric-react";
import React from "react";
import { Modal } from "../../../common/components";
import { gqlMutation } from "../../../common/lib";

export class AddMarker extends React.Component<{ object_type: string, object_id: ID, onClose: () => void }> {
  mutation = gql`
    mutation add_history_marker($object_type: String!, $object_id: ID!, $marker_name: String!) {
      add_history_marker(object_type: $object_type, object_id: $object_id, marker_name: $marker_name) {
        success
      }
    }
  `;

  state = {
    marker_name: ""
  };

  render() {
    const { marker_name } = this.state;

    return gqlMutation(this.mutation, (mutateFunc) => {
      const submit = () =>
        mutateFunc({
          variables: {
            object_type: this.props.object_type,
            object_id: this.props.object_id,
            marker_name
          }
        }).then(this.props.onClose);
      return (
        <Modal
          title="Track version history marker"
          buttons={(m) => (
            <>
              {m.createCancelButton()}
              <PrimaryButton onClick={submit}>Submit</PrimaryButton>
            </>
          )}
          open
          onClose={() => this.props.onClose()}
        >
          <div>
            Enter a note or milestone below to add to Version History (e.g., Ready for Final Review):
            <form onSubmit={submit} className="add-marker-form">
              <TextField
                autoFocus
                value={this.state.marker_name}
                onChange={(e, marker_name) => this.setState({ marker_name })}
                placeholder="Ready for Preliminary Results review"
              />
            </form>
          </div>
        </Modal>
      );
    });
  }
}
