import React from "react";
import { report_show_export_role } from "../../../schemas/Report";
import { GQLReport } from "../../../schemas/schema";

export class RoleSetterBase<P> extends React.Component<P> {
  updateState = () => this.forceUpdate();

  toggleRole(report: GQLReport, role: string, mutateFunc: (input: any) => Promise<any>): void {
    const hide_export_roles = report.hide_export_roles!;
    if (report_show_export_role(report, role)) {
      hide_export_roles.push(role);
    } else {
      hide_export_roles.splice(hide_export_roles.indexOf(role), 1);
    }
    mutateFunc({ variables: { input: { gid: report.gid, hide_export_roles } } });
  }
}
