import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { DragHandle } from "./DragHandle";

export const SortableItem = SortableElement(({ value }) => (
  <li className="row">
    <DragHandle />
    {value}
  </li>
));
