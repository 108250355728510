import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactMarkdown from "react-markdown";

function truncateWords(t: string, m: number) {
  const words = t.split(" ");

  if (words.length > m) {
    t = words.slice(0, m).join(" ");
    t += "...";
  }

  return t;
}

export class MarkdownText extends React.Component<{ source: string, maxWords?: number }> {
  state = { showAll: false };

  truncationMarker() {
    const { showAll } = this.state;
    const showLabel = !showAll ? "more" : "less";
    const showIcon: IconProp = !showAll ? "chevron-down" : "chevron-up";

    return (
      <a className="show-more" onClick={() => this.setState({ showAll: !showAll })}>
        Show {showLabel} <FontAwesomeIcon icon={showIcon} />
      </a>
    );
  }

  render() {
    const { maxWords, source } = this.props;

    let truncated = false;
    let text = source || "";
    if (maxWords) {
      const newText = truncateWords(text, maxWords);
      truncated = text != newText;
      if (!this.state.showAll) {
        text = newText;
      }
    }

    return (
      <>
        <ReactMarkdown>{text}</ReactMarkdown>
        {truncated && this.truncationMarker()}
      </>
    );
  }
}
