import { GQLReportTrack, GQLTrack } from "../../../schemas/schema";
import { IReportElement } from "./ReportElementsTab";

const LABELS = {
  technical_debt: "Technical Debt",
  key_metrics_enabled: "Key Metrics",
  tech_stack: "Technology and Tools",
  value_creation: "Value Creation",
  finding_enabled: "Findings and Recommendations",
  score_context_enabled: "Track Score Context",
  tech_indicators_enabled: "TechIndicators",
  track_summary_enabled: "Track Summary / The Headline",
  report_findings_enabled: "Report Findings",
  report_recommendations_enabled: "Report Recommendations"
};

export const transformReportElements = (track: GQLTrack | GQLReportTrack): IReportElement[] => {
  const elements: IReportElement[] = [];
  for (const [key, value] of Object.entries(JSON.parse(track.report_elements))) {
    elements.push({ name: key, label: LABELS[key], selected: !!value });
  }
  return elements;
};

export * from "./ReportElementsTab";
