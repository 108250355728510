import React from "react";
import { Dropdown, IDropdownOption, IDropdownProps } from "office-ui-fabric-react";
import { sortOptionsAlphabetically } from "./DropDownSelect";

interface IProps extends IDropdownProps {
  orderedAlphabetically?: boolean
}

export class DropdownEnhanced extends React.Component<any> {
  private readonly dropdownRef = React.createRef<any>();
  selectedDefault = false;

  private handleDrownDownFocus(ev: React.FormEvent<HTMLDivElement>) {
    const dropDown = this.dropdownRef.current;
    if (this.props.placeholder && dropDown && !this.selectedDefault) {
      dropDown.setSelectedIndex(ev, 0);
      this.selectedDefault = true;
    }
  }

  constructor(props) {
    super(props);
    if (props.onSelectedKeys) {
      this.state = { currentValue: props.selectedKeys || [] };
    }
  }

  render() {
    const { options, placeholder, orderedAlphabetically } = this.props;
    const props = this.props;
    const propsOverride = {} as any;

    const processedOptions = orderedAlphabetically ? sortOptionsAlphabetically(options) : options;

    if (placeholder && processedOptions[0]?.key != placeholder) {
      processedOptions.unshift({
        key: placeholder,
        text: placeholder,
        disabled: true
      });
    }
    /* This makes a more reasonable API to extract multiple select results from dropdown fields */
    if (props.onSelectedKeys) {
      // @ts-expect-error currentValue is defined on the constructor when !!props.onSelectedKeys
      const { currentValue } = this.state;

      propsOverride.onChange = (e, value: IDropdownOption) => {
        if (e.type == "focus" || !value) {
          return;
        }
        let val;
        if (value.selected) {
          val = ([] as any[]).concat(value.key, ...currentValue);
        } else {
          val = currentValue;
          val.splice(currentValue.indexOf(value.key), 1);
        }
        this.setState({ currentValue: val });
        props.onSelectedKeys(val);
      };
    }

    return (
      <Dropdown
        {...props}
        {...propsOverride}
        options={processedOptions}
        componentRef={this.dropdownRef}
        onFocus={this.handleDrownDownFocus.bind(this)}
      />
    );
  }
}
