import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DefaultButton, PrimaryButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import {
  MakeFingerprintMutation,
  UpdateSettingsMutation,
  UpdateSimpleSettingsMutation,
  Wizard2faSetupMutation
} from "gql/mutations/setting.graphql";
import { CurrentUserQRQuery } from "gql/settings.graphql";
import { gqlMutation } from "../../lib";
import { Modal } from "../Modal";
import { MutationStep } from "./MutationStep";
import { PhoneInputCountrySelect } from "./PhoneInputCountrySelect";
import "./Wizard2FA.style.scss";

interface Props {
  onClose: () => void
}

export default class Wizard2FA extends React.Component<Props> {
  state = {
    stepn: 1,
    phone: "",
    error: false,
    authMethod: "authenticator",
    verificationCode: ""
  };

  mutation = Wizard2faSetupMutation;
  makeFingerprint = MakeFingerprintMutation;
  userMutation = UpdateSettingsMutation;
  userMutationSimple = UpdateSimpleSettingsMutation;

  userMutate: any;
  sendVerificationSMS = async() => {
    const { phone } = this.state;
    const parsed = parsePhoneNumber(phone);
    if (!parsed.countryCallingCode || !parsed.nationalNumber) {
      throw new Error("No valid phone provided");
    }

    return this.mutateFunc({
      variables: {
        country_code: parseInt(parsed.countryCallingCode),
        phone_number: parsed.nationalNumber
      }
    });
  };

  checkVerificationCode = async() => {
    const { verificationCode } = this.state;
    const res = await this.mutateFunc({ variables: { verification_code: verificationCode } });
    if (!res.data.wizard_2fa_setup.success) {
      this.setState({ error: true });
      throw new Error();
    }
    this.setState({ verificationCode: "" });
    return res;
  };

  steps = {
    1: {
      title: "Step 1: Mobile phone authorization",
      content: () => (
        <>
          <div className={"text"}>Enter the mobile phone number that can be used for verification:</div>
          <form onSubmit={() => this.nextStep()}>
            <PhoneInput
              placeholder={"Phone number"}
              autoFocus
              onChange={(phone) => this.setState({ phone })}
              value={this.state.phone}
              countrySelectComponent={PhoneInputCountrySelect}
              defaultCountry={"US"}
            />
          </form>
        </>
      ),
      completeFunc: this.sendVerificationSMS
    },
    2: {
      title: "Step 2: Phone Number Verification",
      content: () => (
        <span className={"phone-verification"}>
          <div className={"text"}>Enter the verification code sent to you via SMS</div>
          <form onSubmit={() => this.nextStep()}>
            <TextField
              autoFocus
              className={"verification-code-input"}
              onChange={(e, verificationCode) => this.setState({ verificationCode })}
              placeholder={"Verification code"}
            />
            {this.state.error && <FontAwesomeIcon icon={"times"} color={"red"} />}
          </form>
          <div className="note">
            Didn't get the SMS?
            <a className={"link"} onClick={this.sendVerificationSMS}>
              Resend
            </a>
          </div>
        </span>
      ),
      completeFunc: async() => {
        await this.checkVerificationCode();
        return await window.apolloClient
          .mutate({
            mutation: this.userMutationSimple,
            variables: {
              m2fa_method: "sms"
            }
          });
      },
      submitLabel: "Verify"
    },
    3: {
      title: "Setting up...",
      content: () => (
        <MutationStep
          name="3"
          mutation={this.makeFingerprint}
          onComplete={(res) => {
            document.cookie = `device_id=${res.data.make_fingerprint.fingerprint}; path=/`;
            this.nextStep();
          }}
        />
      ),
      noButtons: true,
      completeFunc: async() => true
    },
    4: {
      title: "Setup Complete",
      content: () => (
        <>
          You have successfully enabled two-factor authentication to your account.
          <br />
          The next time you log in, you'll be required to provide a security code via SMS.
        </>
      ),
      completeFunc: async() => {
        await window.apolloClient.mutate({ mutation: this.userMutation });
        this.props.onClose();
      },
      submitLabel: "Close"
    }
  };

  nextStep = () => {
    this.setState({ error: false });
    this.step.completeFunc().then(() => {
      this.setState({ stepn: this.state.stepn + 1 });
    });
  };

  prevStep = () => {
    this.setState({ stepn: this.state.stepn - 1 });
  };

  private mutateFunc: any;
  private readonly userQRQuery = CurrentUserQRQuery;

  get step() {
    return this.steps[this.state.stepn];
  }

  render() {
    const { stepn } = this.state;
    const step = this.steps[stepn];
    const submitLabel = step.submitLabel || "Next Step";
    const submitDisabled = step.submitDisabled ? step.submitDisabled() : false;
    const lastStep = stepn == Object.keys(this.steps).length;

    return gqlMutation(this.mutation, (mutateFunc) => {
      this.mutateFunc = mutateFunc;
      return (
        <Modal
          open={true}
          title={step.title}
          checkChanges={false}
          className={"prompt prompt-min-width-400"}
          onClose={() => this.props.onClose()}
        >
          {step.content()}
          {!step.noButtons && (
            <div className="buttons">
              {!lastStep && (
                <>
                  <DefaultButton onClick={() => this.props.onClose()}>Cancel</DefaultButton>
                </>
              )}
              <PrimaryButton disabled={submitDisabled} onClick={() => this.nextStep()}>
                {submitLabel}
              </PrimaryButton>
            </div>
          )}
        </Modal>
      );
    });
  }
}
