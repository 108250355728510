import React from "react";
import ProgressModal from "../../reports/common/ProgressModal";

interface AsyncProcessModalProps {
  channel: string
  params?: any
  children?: (download: (params?: any) => void) => JSX.Element
  modalTitle?: string
  initMessage?: string
  downloadUrl?: string
  onClose?: () => void
  onComplete?: (message: any) => void
}

interface WebSocketDeferModalProps {
  channel: string
  onClose: () => void
  onComplete?: (message: any) => void
  modalTitle?: string
  initMessage?: string
}

export const WebSocketDeferModal = (props: WebSocketDeferModalProps): JSX.Element => {
  const jid = props.channel.split(":").pop();
  const onComplete = props.onComplete ?? ((message) => {
    window.location.href = message.nextUrl;
  });
  const { modalTitle, initMessage } = props;
  return <AsyncProcessModal channel="WebSocketDeferredChannel"
                            onComplete={onComplete}
                            modalTitle={modalTitle ?? "Processing..."}
                            initMessage={initMessage ?? "Processing..."}
                            params={{ jid }} onClose={props.onClose} />;
};

const AsyncProcessModal = (props: AsyncProcessModalProps): JSX.Element => {
  const [download, setDownload] = React.useState<boolean>(!props.children);
  const { modalTitle, initMessage, downloadUrl } = props;
  const content = props.children ? props.children(() => setDownload(true)) : null;
  const onComplete = (message) => {
    setDownload(false);
    if (props.onComplete) {
      props.onComplete(message);
    } else {
      window.location.href = message.downloadUrl ?? downloadUrl;
    }
  };

  const onClose = () => {
    setDownload(false);
    props.onClose?.();
  };

  return <>
    {download && <ProgressModal channelName={props.channel} objectId={JSON.stringify(props.params)}
                                modalTitle={modalTitle ?? "Downloading..."}
                                initMessage={initMessage ?? "Preparing download..."}
                                onComplete={onComplete}
                                onClose={onClose}
                                dataTestId={"download-progress"}
    />}
    {content && content}
  </>;
};

export default AsyncProcessModal;
