import _ from "lodash";

export default class Queue {
  maxItems; number;
  items: any[];
  constructor(maxItems = 50) {
    this.maxItems = maxItems;
    this.items = [];
  }

  push(item) {
    this.items.push(item);
    if (this.items.length > this.maxItems) {
      this.items.shift();
    }
    return this;
  }

  has(value) {
    return this.items.includes(value);
  }

  sort(params) {
    _.sortBy(this.items, params);
  }

  consume() {
    return this.items.shift();
  }

  last(n = 1) {
    return this.items.slice(-n);
  }
}
