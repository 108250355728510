import { Label, TextField } from "office-ui-fabric-react";
import React from "react";
import { withTooltip } from "../../lib";

export interface FieldProps {
  tooltipIcon: any
  tooltip: string
  label: string
  value: string
  disabled?: boolean
  placeholder?: string
  dataTestId: string
  onChange: (e, val) => void
}

export class Field extends React.Component<FieldProps> {
  render() {
    return (
      <>
        <div>
          <Label>
            {this.props.label}
            {withTooltip(this.props.tooltip, this.props.tooltipIcon)}
          </Label>
        </div>
        <div>
          <TextField
            value={this.props.value}
            onChange={this.props.onChange}
            data-testid={this.props.dataTestId}
            disabled={this.props.disabled}
          />
        </div>
      </>
    );
  }
}
