import React from "react";

export const REPORT_EDIT_SAVE_ALL_EVENT = "report_edit.save-all";

interface IUnsavedReportChangesContext {
  toggleSectionStatus: (string, boolean) => void
  hasAnyUnsavedChanges: () => boolean
  invokeSaveAll: () => void
}

export const UnsavedReportChangesContext = React.createContext<IUnsavedReportChangesContext>({
  toggleSectionStatus: (name, state) => {},
  hasAnyUnsavedChanges: () => false,
  invokeSaveAll: () => false
});
