import FroalaEditor from "froala-editor";
import jQuery from "jquery";

if (!window.jQuery) {
  window.jQuery = window.$ = jQuery;
}

// const PWA_SCRIPT = "https://cdn.prowritingaid.com/beyondgrammar/2.0.2893/dist/hayt/bundle.js";
// importScript(PWA_SCRIPT);

window.FroalaEditor = FroalaEditor;

declare global {
  interface Window {
    FroalaEditor: typeof FroalaEditor
  }
}
