import { IChoiceGroupOption } from "office-ui-fabric-react";
import { firstBy } from "thenby";
import parse from "../../corsis_extended_markdown/corsis_extended_markdown_es";
import { GQLAssertion, GQLReportIndicator, GQLTopic } from "../../schemas/schema";

export function get_query_param(name, url = "") {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function create_unload_wrappers(condition, message, callback?) {
  let wrapperUnsub: any;
  const browserWrapper = (e) => {
    if (condition()) {
      e.stopPropagation();
      e.preventDefault();
      e.returnValue = message;
      callback && callback();
    }
    return e;
  };
  const eventWrapper = (e, url) => {
    const navigateAway = () => {
      PubSub.unsubscribe(wrapperUnsub);
      window.removeEventListener("beforeunload", browserWrapper);
      PubSub.publish("navigate", { url, force: true });
    };
    if (condition()) {
      callback && callback();
      if (confirm(message)) {
        navigateAway();
      }
    } else {
      navigateAway();
    }
  };
  PubSub.subscribe("page.beforeunload", eventWrapper);
  window.addEventListener("beforeunload", browserWrapper);
}

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

export function get_track_list(report, tracks) {
  let all_tracks: any[] = new Array(...tracks);
  let n = 0;
  for (const track of all_tracks) {
    if (report.report_tracks!.map((x) => x.track!.id).indexOf(track.id) != -1) {
      all_tracks[n] = report.report_tracks!.filter((x) => x.track!.id == track.id);
    } else {
      if (!report.show_all_tracks) {
        all_tracks[n] = null;
      }
    }
    n++;
  }
  all_tracks = all_tracks.filter((x) => x);
  all_tracks = all_tracks.flat();
  all_tracks = all_tracks.sort(firstBy((x) => x.sort_order));
  return all_tracks;
}

export function indicator_is_visible(indicator: GQLReportIndicator) {
  return indicator.visible != "EXCLUDED";
}

export const IMPLEMENTATION_TAGS = ["FullyImplemented", "PartiallyImplemented", "NotImplemented"];

const EXPANSIONS = {
  [IMPLEMENTATION_TAGS[0]]: "The following best practices are fully implemented",
  [IMPLEMENTATION_TAGS[1]]: "The following best practices are partially implemented",
  [IMPLEMENTATION_TAGS[2]]: "The following best practices are not implemented"
};

export function implementation_tag_to_string(tag: CorsisMDTag, is_plural = false) {
  const expansion = EXPANSIONS[tag.name] || "Select Implementation Level";
  if (!is_plural) {
    return expansion.replace(/are/, "is").replace(/practices/, "practice");
  }
  return expansion;
}

export function assertion_tag_to_string(tag: CorsisMDTag): string {
  if (tag.name !== "Assertion") {
    throw new Error("Not an assertion tag");
  }
  return tag.extraParam || "";
}

export function indicators_for_topic(topic: GQLTopic, indicators: GQLReportIndicator[]) {
  return indicators.filter((x) => x.indicator!.topic && x.indicator!.topic.id == topic.id);
}

export function numberedOptions(input: string[], startAt = 0) {
  let n = startAt;
  const out = [] as any[];

  for (const line of input) {
    out.push({
      key: n,
      text: line,
      value: line
    });
    n++;
  }
  return out;
}

export function scrollToQuery(query: string, delay = 0) {
  // USES JQUERY!
  let marginOffset = 0;
  if ($(".split-view-right").length > 0) {
    marginOffset = parseInt($(".split-view-right").css("marginTop").replace("px", ""));
  }
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  setTimeout(() => window.scroll({
    top:
      scrollTop + document.querySelector(query)!.getBoundingClientRect().top - marginOffset
  }), delay
  );
}

export function scrollToIndicator(indicator: GQLReportIndicator) {
  const query = `[data-indicator-id='${indicator.id}']`;
  return scrollToQuery(query);
}

export function score_to_capability(score: number): number {
  return Math.round(score) / 20.0;
}

export function addKeyFromName(item) {
  item.key = item.name;
  return item;
}

export function addKeyFromValue(item) {
  item.key = item.value;
  return item;
}

export function stringToIChoice(input: string): IChoiceGroupOption {
  return {
    key: input,
    text: input
  };
}

export function logout() {
  // @ts-expect-error
  document.body.style.opacity = 0.5;
  const complete = () => (window.location.href = "/");
  fetch("/users/sign_out", { method: "DELETE" }).then(complete).catch(complete);
}

const timeouts = {};

export function debounce(func, wait = 250) {
  clearTimeout(timeouts[func]);
  timeouts[func] = setTimeout(func, wait);
}

/**
 *
 * @param expanded
 * @param setExpanded
 * @param title
 * @param titleBar JSX component, replaces title
 * @param children
 * @constructor
 * You'll need to catch events inside titleBar to stop them from propagating onClick!
 */

function numeric_context(context: string): any {
  return {
    FullyImplemented: 1,
    PartiallyImplemented: 0.5,
    NotImplemented: 0
  }[context];
}

export function get_corsis_tags(line: string): CorsisMDTag[] {
  const p = parse(line);
  return p.filter((x) => x && x.type == "corsis_extension").map((x) => x.value);
}

export function get_corsis_tags_by_line(text: string): CorsisMDLine[] {
  if (!text) {
    return [];
  }
  return parse(text);
}

export function has_context_tag(tags: CorsisMDTag[]): boolean {
  return tags.filter((x) => x.name && IMPLEMENTATION_TAGS.includes(x.name)).length > 0;
}

export function get_assertions_summary(corsis_md: string | CorsisMDLine[], all_assertions: GQLAssertion[]): string {
  const out: string[] = [];
  let s = tags_from_corsis_md(corsis_md);
  s = s.filter((x) => x.value?.name == "Assertion");
  const used_ids = new Set(s.map((x) => x.value?.params.id));
  for (const tag of all_assertions) {
    if (used_ids.has(tag.id)) {
      continue;
    }
    out.push(`AID:${tag.id}:Eval:NA`);
  }
  for (const tag of s) {
    if (tag.value?.name != "Assertion") {
      continue;
    }
    out.push(`AID:${tag.value?.params.id || ""}:Eval:${numeric_context(tag.value?.params.context).toFixed(1)}`);
  }
  return out.join("\n");
}

function tags_from_corsis_md(corsis_md: string | CorsisMDLine[]): CorsisMDLine[] {
  let s = corsis_md;
  if (typeof s == "string") {
    s = get_corsis_tags_by_line(s);
  }
  return s;
}

export function get_excluded_assertions(
  corsis_md: string | CorsisMDLine[],
  assertions: GQLAssertion[]
): GQLAssertion[] {
  const s = tags_from_corsis_md(corsis_md);
  const used_assertions = get_used_assertion_ids(s);
  return assertions.filter((x) => !used_assertions.has(x.id));
}

function get_used_assertion_ids(s: CorsisMDLine[]) {
  return new Set(
    s
      .filter((x) => x.type == "corsis_extension")
      .filter((x) => x.value.name == "Assertion")
      .map((x) => x.value.params.id)
  );
}

interface ScoredTally {
  unassigned: number
  assigned: number
  total: number
}

export function get_scored_assertion_tally(
  corsis_md: string | CorsisMDLine[],
  assertions: GQLAssertion[]
): ScoredTally {
  /**
   * @returns {unassigned, assigned, total}
   */
  let [unassigned, assigned, total] = [0, 0, 0];
  const s = tags_from_corsis_md(corsis_md);
  const used_assertion_ids = get_used_assertion_ids(s);

  for (const assertion of assertions) {
    if (used_assertion_ids.has(assertion.id)) {
      assigned++;
    } else {
      unassigned++;
    }
    total++;
  }
  return { unassigned, assigned, total };
}

export function get_total_score(corsis_md: string | CorsisMDLine[]): number {
  /**
   * Returns the score for a corsis_md string, based on its assertions.
   * As documented in CAT-3262
   */
  if (!corsis_md) {
    return 0;
  }
  let s = corsis_md;
  if (typeof s == "string") {
    s = get_corsis_tags_by_line(s);
  }
  let z = 0;
  let y = 0;
  for (const line of s) {
    const tag = line.value;
    if (tag?.name != "Assertion") {
      continue;
    }
    const m = numeric_context(tag.params.context);
    const weight = parseInt(tag.params.wt) || 1;
    z += weight;
    y += weight * m;
  }
  if (z == 0) {
    return 0;
  }
  return Math.max(1, (y / z) * 5);
}

export function capability_scores_enabled(): boolean {
  return !!parseInt(localStorage.getItem("capability_scores_enabled") || "0");
}

export function switch_capability_scores(func = undefined as undefined | (() => void)) {
  const set = !capability_scores_enabled();
  localStorage.setItem("capability_scores_enabled", set ? "1" : "0");
  if (func) {
    func();
  }
}
