export class Validations {
  static viewsValidation = function(value) {
    // @ts-expect-error
    const { path, createError } = this;

    for (const view of value) {
      if (!view.name) {
        return false;
      }
    }
    return true;
  };

  static keyMetricsValidation = function(value) {
    return true;
  };

  static tracksValidation = function(value) {
    // @ts-expect-error
    const { path, createError } = this;
    const duplicate_tracks = new Set<string>();
    if (!value || value.length == 0) {
      return createError({ message: "At least one track needs to be defined" });
    }
    for (const track of value) {
      if (duplicate_tracks.has(track.track_id)) {
        continue;
      }
      if (value.filter((x) => x.track_id == track.track_id).length > 1) {
        duplicate_tracks.add(track.track_id);
      }
    }
    for (const track of value) {
      if (!track.focus) {
        if (duplicate_tracks.has(track.track_id)) {
          return createError({
            message:
              "When more than one track of the same kind exists," +
              " track focus becomes required field for both of them."
          });
        }
      }
      if (track.track_id == "13" && (track.add_ons || []).length == 0) {
        // compliance track
        return createError({ message: "Compliance tracks require Add-on selection." });
      }
    }
    return true;
  };

  static indicatorAssignmentsValidation = function(value) {
    // @ts-expect-error
    const { createError } = this;

    if (!value) {
      return false;
    }

    if (typeof value == "string" || (value.length && typeof value[0] == "string")) {
      return true;
    }

    const used_combinations = new Set();
    for (const line of value) {
      if (!line.track || !line.topic || !line.tier) {
        return false;
      }
      const k = `${line.track.id}.${line.topic.id}`;
      if (used_combinations.has(k)) {
        return createError({ message: "You cannot use the same Track / Topic combination more than once." });
      }
      used_combinations.add(k);
    }
    return true;
  };
}
