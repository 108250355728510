import React from "react";

export class ToggleHideContext {
  public static ReactContext = React.createContext({} as ToggleHideContext);
  public static Provider = ToggleHideContext.ReactContext.Provider;
  public static Consumer = ToggleHideContext.ReactContext.Consumer;

  private readonly component: React.Component;

  hiddenElements: string[] = [];

  constructor(component: any) {
    this.component = component;
  }

  updateComponent() {
    this.component.setState({ ToggleHideContext: this });
  }

  isElementHidden = (id: string) => {
    return this.hiddenElements.includes(id);
  };

  isElementShown = (id: string) => {
    return !this.isElementHidden(id);
  };

  toggleHide = (id: string) => {
    if (this.isElementHidden(id)) {
      this.hiddenElements = this.hiddenElements.filter((hiddenId) => hiddenId !== id);
    } else {
      this.hiddenElements = [...this.hiddenElements, id];
    }
    this.updateComponent();
  };
}
