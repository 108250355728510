import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GQLTag, GQLTier, GQLTrack } from "../../../schemas/schema";
import { showFieldError } from "../../lib";
import { IndicatorAssignmentLine } from "./IndicatorAssignmentLine";
import { IAssignmentDefinition } from "./interfaces";

interface IIndicatorAssignmentProps {
  allTracks: GQLTrack[]
  allTags: GQLTag[]
  allTiers: GQLTier[]
  assignments: IAssignmentDefinition[]
  onChange: (assignments: IAssignmentDefinition[]) => void
  form: any
  field: any
  maxAssignments: number
}

export class IndicatorAssignments extends React.Component<IIndicatorAssignmentProps> {
  state = {
    assignments: [] as IAssignmentDefinition[]
  };

  public static defaultProps = {
    maxAssignments: Infinity
  };

  componentWillMount() {
    const { assignments } = this.props;
    // @ts-expect-error
    this.state.assignments = assignments.map(JSON.parse);
    if (assignments.length == 0) {
      this.addAssignment(true);
    }
  }

  setTouched() {
    this.props.form.setFieldTouched(this.props.field.name);
  }

  addAssignment(initial = false) {
    const { assignments } = this.state;
    assignments.push({} as IAssignmentDefinition);
    this.setState({ assignments });
    if (!initial) {
      this.setTouched();
    }
  }

  private alterAssignment(index) {
    const me = this;
    return function(opts) {
      const { assignments } = me.state;
      if (opts.delete) {
        assignments.splice(index, 1);
        return me.updateAssignments(assignments);
      }
      const assignment = assignments[index];
      for (const key in opts) {
        assignment[key] = opts[key];
      }
      assignments[index] = assignment;

      me.updateAssignments(assignments);
    };
  }

  assignment_is_complete = (as) => as.track && as.topic && as.tier && as.tags;

  private updateAssignments(assignments) {
    this.setState({ assignments });
    this.setTouched();
    // this.props.onChange(assignments.filter(this.assignment_is_complete));
    this.props.onChange(assignments);
  }

  render() {
    const { assignments } = this.state;
    const { allTracks, allTags, allTiers } = this.props;
    const showAddButton =
        assignments.length < this.props.maxAssignments &&
        assignments.filter(this.assignment_is_complete)[assignments.length - 1];

    return (
      <div className="controls indicator-assignments">
        {showFieldError(this.props.form, this.props.field)}
        <table>
          <thead>
            <th>Track Name</th>
            <th>Topic</th>
            <th>Tier</th>
            <th>Tags</th>
          </thead>
          <tbody>
            {assignments.map((assignmentDef, i) => (
              <IndicatorAssignmentLine
                  allTracks={allTracks}
                  allTags={allTags}
                  allTiers={allTiers}
                  canDelete={assignments.length > 1}
                  assignment={assignmentDef}
                  alterAssignment={this.alterAssignment(i)}
              />
            ))}
            {showAddButton && (
              <div className={"pointer add-button"} onClick={() => this.addAssignment()}>
                <FontAwesomeIcon icon={["fal", "plus-circle"]} size={"2x"} />
                <label>Add Assignment</label>
              </div>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
