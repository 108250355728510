import { useMutation } from "@apollo/client";
import React from "react";
import { LoadingScreen } from "../components/LoadingScreen";
import { blockPage } from "./renderers";

const GqlMutation = ({ mutations, output, silent, block }) => {
  if (block) {
    React.useEffect(() => () => blockPage("soft", false));
  }
  const loadings = [] as any[];
  const errors = [] as any[];
  let funcs = [] as any[];
  let datas = [] as any[];
  if (!Array.isArray(mutations)) {
    mutations = [mutations];
  }
  for (const mutation of mutations) {
    const [mutate, { loading, error, data }] = useMutation(mutation);
    funcs.push(mutate);
    loadings.push(loading);
    errors.push(error);
    datas.push(data);
  }
  if (!silent && loadings.some((x) => x)) {
    if (block) {
      blockPage("soft");
      return null;
    }
    return <LoadingScreen type={"shimmer"} />;
  } else {
    if (block) {
      blockPage("soft", false);
    }
  }
  if (funcs.length == 1) {
    funcs = funcs[0];
  }
  if (datas.length == 1) {
    datas = datas[0];
  }
  return output(funcs, datas);
};

export function gqlMutation(mutations, output, silent = false) {
  return <GqlMutation mutations={mutations} output={output} silent={silent} block={false} />;
}

export function gqlNullMutation(mutation, output) {
  const mutateFunc = async({ variables }) => {
    return await window.apolloClient.mutate({ mutation, variables, fetchPolicy: "no-cache" });
  };
  return output(mutateFunc);
}

export function gqlMutationBlock(mutation, output) {
  return <GqlMutation mutations={mutation} output={output} silent={false} block={true} />;
}

export function gqlMutationSilent(mutation, output) {
  return gqlMutation(mutation, output, true);
}
