import Fuse from "fuse.js";
import React from "react";
import Autosuggest from "react-autosuggest";
import { fuzzySearcher } from "../../utils";
import "./react-autosuggest.css";

interface Suggestion {
  name: string
  id?: number
}

/*
This component is currently unused, but seems useful. For a start, QuickIndicator search could be based on this.
*/

const renderSuggestion = (suggestion: Suggestion) => <div>{suggestion.name}</div>;

interface FuzzySearcherProps {
  allSuggestions: Suggestion[]
  onSuggestionSelected: (event: any, { suggestion }: { suggestion: Suggestion }) => void
  value: Suggestion | null
}

export class FuzzySearcher extends React.Component<FuzzySearcherProps> {
  state = {
    value: "",
    suggestions: [] as Suggestion[]
  };

  private searcher: Fuse<Suggestion>;
  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.setState({
      suggestions: this.searcher.search(value).map((x) => x.item)
    });
  };

  componentWillReceiveProps(nextProps: Readonly<FuzzySearcherProps>, nextContext: any): void {
    if (nextProps.value && nextProps.value.name != this.state.value) {
      this.setState({ value: nextProps.value.name });
    }
  }

  componentDidMount() {
    const allSuggestions = this.props.allSuggestions;
    this.searcher = fuzzySearcher(allSuggestions);
  }

  onChange = (event: any, { newValue }: { newValue: string }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  componentDidUpdate(): void {
    const context = document.querySelectorAll("li.react-autosuggest__suggestion");
    // @ts-expect-error
    const instance = new Mark(context);
    setTimeout(() => {
      instance.unmark({ done: () => instance.mark(this.state.value) });
    }, 0);
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Select a user",
      value,
      onChange: this.onChange
    };
    return (
      <Autosuggest
        getSuggestionValue={() => this.state.value}
        inputProps={inputProps}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={this.props.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        suggestions={suggestions}
      />
    );
  }
}
