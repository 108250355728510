import gql from "graphql-tag";

export const keyListItemSearchQuery = gql`
  query key_list_item_search(
    $key_type: String!
    $tracks: [ID!]
    $impacts: [String!]
    $title: String
    $description: String
    $users: [ID!]
    $industry_groups: [ID!]
    $clients: [ID!]
    $companies: [ID!]
  ) {
    key_list_item_search(
      key_type: $key_type
      tracks: $tracks
      impacts: $impacts
      title: $title
      description: $description
      users: $users
      industry_groups: $industry_groups
      clients: $clients
      companies: $companies
    ) {
      created_at
      updated_at
      id
      impact
      description
      title
      priority
      timeframe
      cost
      client {
        name
      }
      company {
        name
      }
      last_modified_by {
        full_name
      }
    }
  }
`;

export const bottomSearchQueries = gql`
  query bottomsearch_queries {
    industry_groups {
      id
      name
    }
    clients {
      id
      name
    }
    companies {
      id
      name
    }
    users(role: "__REPORT_ANY") {
      id
      full_name
    }
  }
`;

export const topSearchQueries = gql`
  query topsearch_queries {
    tracks {
      id
      name
    }
    topics {
      id
      name
      track {
        id
      }
    }
  }
`;
