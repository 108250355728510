import cn from "classnames";
import { CommandBarButton, IContextualMenuItem } from "office-ui-fabric-react";
import React from "react";
import { withTooltip } from "../../../common/lib";
import { AddMarker } from "../ReportVersionHistory";

interface Props {
  className?: string
}
export class ReportHistoryBase<P extends Props> extends React.Component<P> {
  state = {
    versionHistory: false,
    addMarker: false
  };

  protected get object_id() {
    return "0";
  }

  protected renderVersionHistory() {
    return <></>;
  }

  protected get object_type() {
    return "";
  }

  render() {
    const { versionHistory, addMarker } = this.state;
    const menuPropsItems = [
      {
        key: "history",
        text: "View History",
        iconProps: { iconName: "eye" },
        onClick: () => this.setState({ versionHistory: true })
      },
      {
        key: "marker",
        text: "Add Marker",
        iconProps: { iconName: "flag" },
        onClick: () => this.setState({ addMarker: true })
      }
    ] as IContextualMenuItem[];
    return (
      <>
        {versionHistory && this.renderVersionHistory()}
        {addMarker && (
          <AddMarker
            object_type={this.object_type}
            object_id={this.object_id}
            onClose={() => this.setState({ addMarker: false })}
          />
        )}
        {withTooltip(
          "Access full version history",
          <CommandBarButton
            data-testid="history"
            className={cn("history", this.props.className)}
            iconProps={{ iconName: "history" }}
            menuProps={{
              items: menuPropsItems
            }}
          />
        )}
      </>
    );
  }
}
