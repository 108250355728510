import { deep_clone } from "@/common/utils";
import React from "react";
import Select from "react-select";
import { IObjectSelectorPropsBase } from "./interfaces";

interface IProps extends IObjectSelectorPropsBase {
  value: GQLObject[]
  onChange: (object: GQLObject[]) => void
  options: GQLObject[]
}

export class ObjectSelector extends React.Component<IProps> {
  state = {
    selectedOption: null
  };

  handleChange = (selectedOption: GQLObject | GQLObject[]) => {
    setTimeout(() => this.control.current?.focus(), 0);
    let objects = selectedOption;
    if (!Array.isArray(objects)) {
      objects = [objects];
    }
    if (selectedOption == null) {
      objects = [];
    }
    this.props.onChange(objects);
  };

  private readonly control = React.createRef<any>();

  sortObjects(objects) {
    return objects.sort();
  }

  getLabel(option) {
    return option.name;
  }

  render() {
    let { isMulti, value, options, ...props } = this.props;
    options = deep_clone(options).filter((x) => x);
    options = this.sortObjects(options);

    return (
      <Select
        {...props}
        menuPortalTarget={this.props.usePortal ? document.querySelector("body") : undefined}
        isDisabled={!!this.props.readonly}
        ref={this.control}
        options={options}
        value={value}
        getOptionLabel={(option: GQLObject) => this.getLabel(option)!}
        getOptionValue={(option: GQLObject) => option.id + ""}
        placeholder={this.props.readonly ? "" : "Select..."}
        className={"basic-multi-select " + (this.props.className ? this.props.className : "")}
        onChange={this.handleChange}
        isSearchable={true}
        isClearable
        isMulti={isMulti}
      />
    );
  }
}
