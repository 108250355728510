import React from "react";
import { SortableContainer } from "react-sortable-hoc";

export const SortableContainerEl = SortableContainer(({ headers, children }) => {
  return (
    <ul className="controls track-selector">
      <li className="row">{headers}</li>
      {children}
    </ul>
  );
});
