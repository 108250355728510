import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { GQLTopic } from "../../../schemas/schema";

interface Props {
  topic: GQLTopic
  onSortEnd: (params: any) => void
}

export class TopicContainer extends React.Component<Props> {
  helperContainer = () => document.querySelector(".ms-Modal") as HTMLElement;

  render() {
    const { topic, children, onSortEnd } = this.props;
    const Component = SortableContainer(({ children }) => (
      <ul style={{ zIndex: 10000001, position: "relative" }} className="topic" id={`topic-sortable-${topic.gid}`}>
        {children}
      </ul>
    ));

    return (
      <Component children={children} onSortEnd={onSortEnd} pressDelay={200} helperContainer={this.helperContainer} />
    );
  }
}
