import { Query } from "@apollo/client/react/components";
import React from "react";
import { QueryWrapper } from "./QueryWrapper";

function _gqlQuery(query, variables, output, extraVars = {}) {
  return <QueryWrapper query={query} variables={variables} output={output} extraVars={extraVars} />;
}

export function gqlQuerySilent(query, variables, output) {
  if (!output) {
    output = variables;
    variables = {};
  }
  return (
    <Query<any, any> query={query} variables={variables}>
      {(result) => {
        if (result.loading || result.error != null) return null;

        return output(result.data);
      }}
    </Query>
  );
}

export function gqlQuery(query, variables, output, extraVars);
export function gqlQuery(query, variables, output);
export function gqlQuery(query, variables, output);
export function gqlQuery(query, output);

export function gqlQuery(query, variablesOrOutput, output = null, extraVars = {}) {
  let variables = variablesOrOutput;
  if (!output) {
    variables = {};
    output = variablesOrOutput;
  }
  return _gqlQuery(query, variables, output, extraVars);
}
