import cn from "classnames";
import * as React from "react";
import Rating from "react-rating";
import { withTooltip } from "../../common/lib";

interface TooltipTexts {
  [key: string]: string[]
}
const tooltipTexts: TooltipTexts = {
  "fire-icon": ["Medium Priority", "High Priority", "Critical Priority"],
  "dollar-icon": ["Est. $1,000-$9,999", "Est $10,000-$99,999", "Est $100,000-$999,999", "Est > $1,000,000"]
};

interface RatingFieldProps {
  icon: string
  initialRating: number
  onChange?: (p: number) => void
  hideEmpty?: boolean
  hideTooltips?: boolean
}

export class RatingField extends React.Component<RatingFieldProps> {
  render() {
    const { icon, onChange, initialRating, hideEmpty } = this.props;
    return (
      <Rating
        readonly={!onChange}
        emptySymbol={this.getRatingSymbols(icon, false)}
        fullSymbol={this.getRatingSymbols(icon, true)}
        initialRating={initialRating}
        onChange={onChange}
        stop={hideEmpty ? initialRating : tooltipTexts[icon].length}
      />
    );
  }

  private getRatingSymbols(icon: string, active: boolean) {
    let render = (t, el) => withTooltip(t, el);
    if (this.props.hideTooltips) {
      render = (t, el) => el;
    }
    return tooltipTexts[icon].map((val) => render(val, <span className={cn(icon, { active })} />));
  }
}
